import styled from "styled-components";
import { accentColor, borderColor, darkBlue, lightBlue } from "../../ui/colors";
import { FormControlLabel, Typography } from "@mui/material";

export const Wrapper = styled.div``;

export const ButtonWrapper = styled.div`
  width: 192px;
  padding-left: 32px;
`;

export const IframeStyled = styled.iframe`
  border: none;
  display: block;
  border: 1px solid gray;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 40px;
`;

export const ButtonsGroup = styled.div`
  display: flex;
  align-items: center;
`;

export const FormControlLabelStyled = styled<{ $active: boolean }>(
  FormControlLabel
)`
  padding: 15px 21px;
  border: 1px solid ${borderColor};
  border-radius: 8px;
  height: 60px;
  margin-bottom: 24px;
  margin-left: 0;
  &:last-child {
    margin-bottom: 0;
  }

  .MuiFormControlLabel-label {
    margin-left: 8px;
    font-size: 20px;
    line-height: 24px;
    color: ${darkBlue};
  }

  ${(props) => {
    if (props.$active) {
      return `
          background-color: ${lightBlue};
          border-color: ${accentColor};
        `;
    }
  }}
`;

export const FormControlsWrapper = styled.div`
  display: flex;
  gap: 60px;
  margin-bottom: 36px;
`;

export const FormControlWrapper = styled.div`
  flex-basis: 50%;
  flex-grow: 1;
  & > * {
    width: 100%;
  }
  .MuiFormControlLabel-root {
    margin-right: 0;
  }
`;

export const FrameFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 36px;
`;

export const FilterWrapper = styled.div`
  display: flex;
  margin-bottom: 24px;
  & > * {
    flex-grow: 1;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

export const SectionTitleTypographyStyled = styled(Typography)`
  margin-bottom: 24px;
  line-height: 1;
  color: ${darkBlue};
  line-height: 25px;
`;

export const SearchButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
