import { rootPages } from "constants";
import React from "react";
import { Currency } from "shared/types/cyrrency";
import { Button, Loader, Paper, Title } from "ui";
import {
  ButtonWrapper,
  InfoRow,
  InfoWrapper,
  LoadingWrapper,
  Wrapper,
} from "./Summary.styled";
import Divider from "@mui/material/Divider";

type SummaryProps = {
  subtotal: string;
  handleRequestQuote: () => void;
  loading: boolean;
  discount: number;
  currency: Currency;
};

const Summary = ({
  subtotal,
  handleRequestQuote,
  loading,
  discount,
  currency,
}: SummaryProps) => {
  const handleClickCheckout = () => {
    console.log("amplitude!!!");
  };

  const isDiscount = typeof discount === "number";

  return (
    <Wrapper>
      {loading ? (
        <LoadingWrapper>
          <Loader />
        </LoadingWrapper>
      ) : null}
      <Paper smallPaddings>
        <Title>Summary</Title>

        <InfoWrapper>
          <InfoRow>
            <span>Subtotal</span>
            <span>
              <b>{subtotal}</b>
            </span>
          </InfoRow>
        </InfoWrapper>

        <Divider />

        <InfoWrapper>
          <InfoRow>
            <span>Delivery</span>
            <span>Calculated at checkout</span>
          </InfoRow>
          {isDiscount && (
            <InfoRow>
              <span>Discount</span>
              <span>
                {discount} {currency.abbr}
              </span>
            </InfoRow>
          )}
        </InfoWrapper>

        <ButtonWrapper>
          <Button
            type="outlined"
            fullWidth
            onClick={handleRequestQuote}
            bold
            data-test="request_quote_button"
            dataTestAttr="request_quote_button"
            href=""
            className=""
          >
            Request Quote
          </Button>
        </ButtonWrapper>

        <Button
          fullWidth
          onClick={handleClickCheckout}
          bold
          href={`/${rootPages.CHECKOUT}`}
        >
          Checkout
        </Button>
      </Paper>
    </Wrapper>
  );
};

export default Summary;
