import styled from "styled-components";
import { Link } from "../index";
import { darkBlue } from "ui/colors";

export const Wrapper = styled.div`
  background: #fff;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: inherit;
  overflow: hidden;

  .product-card__footer {
    padding-top: 20px;
  }

  .product-card {
    background-color: red;

    &__list-wrapper {
      padding-bottom: 15px;
      padding-right: 20px;
      padding-left: 20px;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
      display: none;
      position: absolute;
      top: 100%;
      right: 0;
      left: 0;
      background-color: white;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    }
  }
`;

Wrapper.displayName = "Wrapper";

export const ImageWrapper = styled.div`
  border-radius: inherit;
`;
ImageWrapper.displayName = "ImageWrapper";

export const ListWrapper = styled.div``;
ListWrapper.displayName = "ListWrapper";

export const Code = styled(Link)`
  font-weight: 500;
  font-size: 21px;
  margin: 0 0 20px 0;
  cursor: pointer;
  display: inline-block;
  text-decoration: none !important;
  color: ${darkBlue};
  word-break: break-all;

  &:hover {
    opacity: 0.8;
  }
`;
Code.displayName = "Code";

export const ImageLink = styled(Link)`
  display: block;
  padding-bottom: 136px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  position: relative;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  overflow: hidden;
`;
ImageLink.displayName = "ImageLink";

export const Content = styled.div`
  padding: 20px 24px 24px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
`;
Content.displayName = "Content";

export const Footer = styled.footer`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
`;
Footer.displayName = "Footer";

export const FooterRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
`;
FooterRow.displayName = "FooterRow";
