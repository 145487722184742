import styled from "styled-components";

export const OrderHeaderWrapper = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 22px;
`;

export const OrderTitleWrapper = styled.header`
  display: flex;
  margin: 12px 0;
`;

export const OrderTags = styled.div`
  display: flex;
  align-items: center;
  margin-left: 18px;
  & > * {
    margin-right: 8px;
  }
`;
