import React from "react";
import { Tabs } from "../index";
import { links } from "./ProfileSidebar.constants";
import { getLinkHref } from "./ProfileSidebar.utils";
import { SideBarContainerLink } from "./ProfileSidebar.types";

type ProfileSidebarProps = {
  alias: string;
  title?: string | null;
  username?: string;
  avatarLetters?: string;
  handleClick: (link: SideBarContainerLink) => void;
  handleLogout?: () => void;
};

const ProfileSidebar = ({ alias, handleClick }: ProfileSidebarProps) => (
  <Tabs
    navigation={
      <>
        {links.map((link, index) => {
          const active = alias === link.alias;

          return (
            <Tabs.Button
              key={index}
              active={active}
              onClick={() => handleClick(link)}
              to={getLinkHref(link.alias)}
            >
              {link.title}
            </Tabs.Button>
          );
        })}
      </>
    }
  />
);

export default ProfileSidebar;
