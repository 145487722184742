import { common } from "constants";
import React from "react";
import { Button } from "ui";
import { Description, NotFoundImage, Title, Wrapper } from "./NotFound.styled";

type NotFoundProps = {
  message?: string;
};

const NotFound = ({ message = "Products not found" }: NotFoundProps) => {
  return (
    <Wrapper>
      <NotFoundImage />
      <Title variant="h3">{message}</Title>
      <Description variant="body1">
        If you didn't find the compound you were looking for, please send us a
        request with the details of the compound you need, and our team will do
        their best to assist you.
      </Description>
      <Button type="outlined-white" size="small" href={common.CONTACTS_HREF}>
        Contact Our Team
      </Button>
    </Wrapper>
  );
};

export default NotFound;
