import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { events, useAmplitudeState } from "../../context/amplitude-context";
import { userActions, userSelectors } from "../../redux/user";
import { fetchLogout } from "../../redux/user/api";
import ProfileSidebar from "./ProfileSidebar";
import { SideBarContainerLink } from "./ProfileSidebar.types";
import { getUsernameAndAvatarLetters } from "./ProfileSidebar.utils";

type ProfileSidebarContainerProps = {
  alias?: string;
};

const ProfileSidebarContainer = ({ alias }: ProfileSidebarContainerProps) => {
  const dispatch = useDispatch();
  const profile = useSelector(userSelectors.selectProfile);
  const { amplitude } = useAmplitudeState();
  const { username, avatarLetters } = getUsernameAndAvatarLetters(profile);
  const title =
    profile && profile.title !== "Undefined" ? `${profile.title}.` : null;

  const handleLogout = () => {
    dispatch(
      userActions.logout({
        redirectTo: "/",
        cb: async () => {
          amplitude.logEvent(events.logout);
          await fetchLogout();
        },
      })
    );
  };

  const handleClick = (link: SideBarContainerLink) => {
    const alias = link?.alias;
    if (alias === "profile") {
      amplitude.logEvent(events.profile_click);
    } else if (alias === "orders") {
      amplitude.logEvent(events.profile_orders_click);
    } else if (alias === "address-book") {
      amplitude.logEvent(events.profile_addresses_click);
    } else if (alias === "change-password") {
      amplitude.logEvent(events.profile_changepass_click);
    }
  };

  return (
    <ProfileSidebar
      handleLogout={handleLogout}
      alias={alias}
      username={username}
      avatarLetters={avatarLetters}
      title={title}
      handleClick={handleClick}
    />
  );
};

export default ProfileSidebarContainer;
