import React from "react";
import { Box } from "@mui/material";
import { CustomizedTooltip } from "../../../ui";
import { InfoIcon } from "../../../ui/icons";
import { addZeroes } from "../../../utils";
import ZeroCostsCalculatedTooltip from "../../../pages/profile/pages/Order/parts/ZeroCostsCalculatedTooltip";
import {
  OrderDetailsItem,
  OrderDetailsWrapper,
  SubtotalWithHintWrapper,
} from "./OrderPricesDetails.styled";

type OrderDetailsProps = {
  price: any;
  currency?: string;
  isAnyItemInquery?: boolean;
  type?: string;
};

const OrderPricesDetails = ({
  price,
  currency,
  isAnyItemInquery,
  type,
}: OrderDetailsProps) => {
  const showZeroCostsTooltip =
    price?.costsCalculated === false && type === "QUOTE";

  return (
    <OrderDetailsWrapper>
      <OrderDetailsItem>
        <Box>
          <SubtotalWithHintWrapper>
            <span>Subtotal</span>
            {isAnyItemInquery && (
              <CustomizedTooltip
                title="Order includes feasible compounds which will be confirmed by our sales representative"
                placement="right"
              >
                <InfoIcon />
              </CustomizedTooltip>
            )}
          </SubtotalWithHintWrapper>
          <span>
            {addZeroes(price?.subtotal)} {currency}
          </span>
        </Box>
      </OrderDetailsItem>
      <OrderDetailsItem>
        <Box>
          <span>
            Handling
            {showZeroCostsTooltip && <ZeroCostsCalculatedTooltip />}
          </span>
          <span>
            {addZeroes(price?.handling || 0)} {currency}
          </span>
        </Box>

        <Box>
          <span>
            Shipping
            {showZeroCostsTooltip && <ZeroCostsCalculatedTooltip />}
          </span>
          <span>
            {addZeroes(price?.shipping)} {currency}
          </span>
        </Box>
      </OrderDetailsItem>

      <OrderDetailsItem>
        <Box>
          <span>Tax</span>
          <span>
            {addZeroes(price?.tax)} {currency}
          </span>
        </Box>
        <Box>
          <span>Discount</span>
          <span>
            {addZeroes(price?.discount)} {currency}
          </span>
        </Box>
      </OrderDetailsItem>
      <OrderDetailsItem className="last">
        <Box>
          <span>
            <b>Total price</b>
          </span>
          <span>
            <b>
              {addZeroes(price?.total)} {currency}
            </b>
          </span>
        </Box>
      </OrderDetailsItem>
    </OrderDetailsWrapper>
  );
};

export default OrderPricesDetails;
