import styled from "styled-components";
import { blue } from "ui/colors";

export const PageWrapper = styled.div`
  padding-bottom: 140px;
  padding-top: 30px;
`;

export const OrderItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 28px;
`;

export const OrderItemCode = styled.div`
  margin-bottom: 8px;
  font-weight: bold;
  span {
    font-weight: normal;
    color: ${blue};
  }
`;

export const OrderItemPrice = styled.div`
  text-align: right;
  font-weight: bold;
  margin-left: auto;
`;

export const OrderItemDetails = styled.div`
  color: ${blue};
`;

export const SectionContent = styled.div`
  margin-top: 24px;
`;
