import { FullWidthLoader } from "parts";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { checkoutSelectors } from "redux/checkout";
import { currencySelectors } from "redux/currency";
import { Currency } from "shared/types/cyrrency";
import { Shipping } from "shared/types/shipping";
import { Modal } from "ui";
import constants from "ui/constants";
import { customFetch } from "utils";
import { RequestMethods } from "utils/customFetch";
import RushDeliveryModalTable from "./RushDeliveryModal.table";
import {
  prepareShippingData,
  getDeliveryData,
} from "./RushDeliveryModal.utils";
import { DeliveryData, ShippingData } from "./RushDeliveryModal.types";
import { TablesWrapper } from "./RushDeliveryModal.styled";

const CONTAINER_PADDINGS_X2 = 128;

type RushDeliveryModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

const RushDeliveryModal = ({ open, setOpen }: RushDeliveryModalProps) => {
  const checkoutData = useSelector(checkoutSelectors.selectData);
  const currency: Currency = useSelector(currencySelectors.selectCurrent);
  const [rushDeliveryList, setRushDeliveryList] = useState<DeliveryData[]>([]);
  const [standardDeliveryList, setStandardDeliveryList] = useState<
    DeliveryData[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);

  const onModalOpen = async () => {
    setIsLoading(true);
    const shipping: Shipping = checkoutData?.shipping;
    const data: ShippingData = prepareShippingData({ shipping, currency });

    const res = await customFetch({
      method: RequestMethods.POST,
      url: `cart/deliveryTerms`,
      data,
    });

    if (res[0]) {
      const transformed = getDeliveryData(res[0]);

      if (transformed?.rush?.length) {
        setRushDeliveryList(transformed.rush);
      }

      if (transformed?.standard?.length) {
        setStandardDeliveryList(transformed.standard);
      }
    }

    setIsLoading(false);
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      width={constants.maxWidth - CONTAINER_PADDINGS_X2}
      onTransitionEnter={onModalOpen}
    >
      {isLoading ? (
        <FullWidthLoader />
      ) : (
        <TablesWrapper>
          {Boolean(rushDeliveryList.length) && (
            <RushDeliveryModalTable
              list={rushDeliveryList}
              title="Available for Rush Delivery"
            />
          )}

          {Boolean(standardDeliveryList.length) && (
            <RushDeliveryModalTable
              list={standardDeliveryList}
              title="Regular Delivery only"
            />
          )}
        </TablesWrapper>
      )}
    </Modal>
  );
};

export default RushDeliveryModal;
