import { Typography } from "@mui/material";
import { endpoints } from "constants";
import useLoaderFullPage from "features/Loaders/hooks/useLoaderFullPage";
import { useFormik } from "formik";
import React, { useState } from "react";
import { Button, Input, Paper, Select } from "ui";
import { darkBlue } from "ui/colors";
import { customFetch } from "utils";
import { RequestMethods } from "utils/customFetch";
import {
  ButtonWrapper,
  ErrorMessage,
  FlexWrapper,
} from "./CertificatesCOA.styled";

const LANGUAGE_OPTIONS = [
  { title: "English", value: "ENGLISH" },
  { title: "German", value: "GERMAN" },
  { title: "French", value: "FRENCH" },
];

const initialValues = {
  lotNumber: "",
  language: "",
};

const CertificatesCOA = () => {
  const [error, setError] = useState("");
  const { showLoaderFillPage, hideLoaderFillPage } = useLoaderFullPage();

  const fetchCoaCertificate = async (certificateData: {
    lotNumber: string;
    language: string;
  }) => {
    return customFetch({
      method: RequestMethods.POST,
      url: endpoints.CERTIFICATES_COA,
      data: {
        lotNumber: certificateData.lotNumber,
        language: certificateData.language,
      },
      config: {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
        },
      },
    });
  };

  const handleSubmit = async (certificateData: {
    lotNumber: string;
    language: string;
  }) => {
    try {
      setError("");
      showLoaderFillPage();

      const res = await fetchCoaCertificate(certificateData);
      const { lotNumber, language } = certificateData;

      if (res[0]) {
        const dirHandle = await window.showDirectoryPicker();
        const fileHandle = await dirHandle.getFileHandle(
          `${lotNumber}_${language}.zip`,
          {
            create: true,
          }
        );

        const writable = await fileHandle.createWritable();
        await writable.write(res[0]);
        await writable.close();
      } else {
        const uint8Array = new Uint8Array(res[1]?.data);
        const jsonString = new TextDecoder("utf-8")?.decode(uint8Array);
        const decodedResponse = JSON.parse(jsonString);
        setError(decodedResponse.message);
      }
    } catch (err) {
      if (err?.name === "AbortError") {
        return;
      } else {
        console.log(err);
      }
    } finally {
      hideLoaderFillPage();
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async (formData) => {
      await handleSubmit(formData);
    },
  });

  return (
    <Paper>
      <Typography variant="h5" fontWeight={600} color={darkBlue}>
        CoA Certificates
      </Typography>
      <form data-test="coa-submit-form" onSubmit={formik.handleSubmit}>
        <FlexWrapper>
          <Input
            label="LOT Number"
            name="lotNumber"
            value={formik.values.lotNumber}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            data-test="lot-number-input"
            placeholder="Enter number"
          />
          <Select
            options={LANGUAGE_OPTIONS}
            value={formik.values.language}
            onChange={formik.handleChange}
            label="Language"
            id="language"
            onBlur={formik.handleBlur}
            placeholder="Select"
          />
        </FlexWrapper>
        <ButtonWrapper>
          <Button
            type="success"
            fullWidth
            disabled={formik.isSubmitting}
            dataTestAttr="button-download"
          >
            Download
          </Button>
        </ButtonWrapper>
        {Boolean(error) && <ErrorMessage>{error}</ErrorMessage>}
      </form>
    </Paper>
  );
};

export default CertificatesCOA;
