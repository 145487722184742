import React from "react";
import styled from "styled-components";
import { CustomizedTooltip } from "ui";
import { blue } from "ui/colors";
import { InfoIcon } from "ui/icons";

const ZeroCostsCalculatedTooltip = () => {
  return (
    <CustomizedTooltip
      title="Couldn’t be properly calculated due to address not filled"
      sx={{
        maxWidth: 180,
      }}
    >
      <IconStyled>
        <InfoIcon />
      </IconStyled>
    </CustomizedTooltip>
  );
};

export const IconStyled = styled.span`
  margin-left: 8px;
  margin-right: 8px;
  transform: translateY(1px);

  svg path {
    fill: ${blue};
  }
`;

export default ZeroCostsCalculatedTooltip;
