import { colors } from "@mui/material";
import { createTheme } from "@mui/material";
import { defaultTheme } from "./constants/theme";
import {
  accentColor,
  borderColor,
  lightBlue,
  progressBarDark,
  progressBarLight,
} from "./ui/colors";

// A custom theme for this app
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1360,
      xl: 1536,
    },
  },
  palette: {
    borderColor: defaultTheme.borderColor,
    primary: {
      main: "#000",
    },
    secondary: {
      main: "#19857b",
    },
    error: {
      main: colors.red.A700,
      text: "#d92d26",
    },
    background: {
      default: "white",
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
    subtitle1: {
      fontSize: "22px",
      fontWeight: 700,
    },
    h1: {
      fontSize: "32px",
      fontWeight: 700,
    },
    h2: {
      fontSize: "28px",
      fontWeight: 600,
    },
    h3: {
      fontSize: "24px",
      fontWeight: 500,
    },
    h4: {
      fontSize: "20px",
      fontWeight: 700,
    },
    h5: {
      fontSize: "16px",
      fontWeight: 400,
    },
    h6: {
      fontSize: "14px",
      fontWeight: 400,
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-root": {
            borderRadius: "8px",
            minHeight: "48px",
          },
          "& .MuiOutlinedInput-root": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: `${borderColor}`,
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline":
              {
                borderWidth: "1px !important",
                borderColor: `${accentColor} !important`,
              },
          },
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        input: {
          "&::placeholder": {
            color: `${borderColor}`,
            opacity: 1,
          },
        },
      },
    },

    MuiRadio: {
      styleOverrides: {
        root: {
          color: defaultTheme.linkColorDarken,
          padding: "5px",
          "&.Mui-checked": {
            color: defaultTheme.linkColorDarken,
          },
        },
      },
    },

    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "&.MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment":
            {
              right: "16px",
            },
          "&.Mui-focused": {
            border: 0,
            outline: 0,
          },
          "& .MuiInputBase-root": {
            borderRadius: "8px",
            paddingLeft: "12px",
            paddingRight: "45px",
            "& input::placeholder": {
              color: borderColor,
              opacity: 1,
            },
            "& .MuiAutocomplete-input": {
              padding: "0 4px",
            },
          },
          "& .MuiChip-root": {
            height: "24px",
            margin: "3px 4px",
          },
          "& .MuiChip-label": { fontSize: "14px", paddingRight: "10px" },
          ".MuiAutocomplete-tag": {
            background: lightBlue,
          },
          "& .MuiOutlinedInput-notchedOutline": { borderColor: borderColor },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline":
            {
              borderWidth: "1px !important",
              borderColor: `${accentColor} !important`,
            },
          "& .MuiChip-root .MuiChip-deleteIcon": {
            fontSize: "12px",
            color: accentColor,
            marginRight: "10px",
          },
        },
      },
    },

    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: 9,
          borderRadius: 5,
          backgroundColor: progressBarLight,
        },
        bar: {
          backgroundColor: progressBarDark,
        },
      },
    },
  },
});

export default theme;
