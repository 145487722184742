import styled from "styled-components";
import { Button, Link } from "ui/index";
import { blue, darkBlue } from "ui/colors";

export const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  color: ${darkBlue};
  gap: 28px;
  padding: 18px 16px;
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Image = styled(Link)`
  width: 180px;
  height: 180px;
  display: block;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 12px;
`;

export const Code = styled(Link)`
  color: ${darkBlue};
  box-sizing: border-box;
  min-width: 160px;
  flex-grow: 1;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none !important;
`;
export const PriceList = styled.div`
  max-width: 354px;
  width: 100%;
  flex-grow: 1;
`;
export const AddToCartButton = styled(Button)`
  width: 36px;
  height: 36px;
  padding: 0;
  & svg {
    width: 22px;
    height: 22px;
  }
`;

export const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const ProductInfoItem = styled.div`
  display: flex;
  font-size: 12px;
  line-height: 20px;
  justify-content: space-between;
  color: ${blue};
`;
