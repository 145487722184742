import styled from "styled-components";
import { accentColor } from "ui/colors";
import { Link } from "react-router-dom";

export const BackLinkStyled = styled(Link)`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
  color: ${accentColor};
  text-decoration: none;
`;
