import endpoints from "../../../../constants/endpoints";
import customFetch from "../../../../utils/customFetch";

const initParams = {
  retry: 5,
  retryDelay: 300,
};

export const fetchUserDetails = async () => {
  return customFetch(
    {
      url: endpoints.USER_PROFILE,
    },
    initParams
  );
};
