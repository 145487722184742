import { rootPages } from "constants";
import { SideBarContainerLink } from "./ProfileSidebar.types";

export const links: SideBarContainerLink[] = [
  {
    title: "Orders",
    alias: "orders",
  },
  {
    title: "Profile",
    alias: rootPages.PROFILE,
  },
  {
    title: "Address book",
    alias: "address-book",
  },
  {
    title: "Tax Exempt Certificates",
    alias: "tax-free-certificates",
  },
  {
    title: "Billing",
    alias: "billing",
  },
  {
    title: "CoA Certificates",
    alias: "certificates-coa",
  },
];
