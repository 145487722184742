import React from "react";
import { ArrowLeftIcon } from "ui/icons";
import { BackLinkStyled } from "./BackLink.styled";
import { LinkProps } from "react-router-dom";

interface BackLinkProps extends LinkProps {
  children: React.ReactNode;
}

const BackLink = ({ children, ...props }: BackLinkProps) => {
  return (
    <BackLinkStyled {...props}>
      <ArrowLeftIcon />
      {children}
    </BackLinkStyled>
  );
};

export default BackLink;
