import { CartItem } from "shared/types/cartItem";
import {
  DeliveryData,
  PrepareShippingDataParams,
  ShippingData,
  DeliveryTypes,
} from "./RushDeliveryModal.types";

export const prepareShippingData = ({
  shipping = {},
  currency,
}: PrepareShippingDataParams): ShippingData => {
  return {
    currency: currency.abbr,
    shippingAddress: {
      id: shipping.id,
      company: shipping.company,
      country: shipping.country,
      state: shipping.state,
      city: shipping.city,
      addressLine1: shipping.addressLine1,
      addressLine2: shipping.addressLine2,
      zip: shipping.zip,
    },
  };
};

export const getDeliveryData = (orderData?: {
  rush?: CartItem[];
  standard?: CartItem[];
}): DeliveryTypes => {
  const rush =
    orderData?.rush.map((cartItem) => extractDeliveryData(cartItem)) || [];
  const standard =
    orderData?.standard.map((cartItem) => extractDeliveryData(cartItem)) || [];

  return { rush, standard };
};

export const extractDeliveryData = (cartItem: CartItem): DeliveryData => ({
  catalogId: cartItem.code,
  packing: cartItem.packingMaterial,
  amount: cartItem.amount,
  location: cartItem.location || "-",
  deliveryTime: cartItem.deliveryTermType?.terms,
});
