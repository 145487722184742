import Typography from "@mui/material/Typography";
import React from "react";

const TermsOfUseContent = () => {
  return (
    <div className="content">
      <Typography variant="h2">1. Introduction</Typography>
      <p>
        1.1. EnamineStore.com is an electronic service for online browsing and
        shopping for products supplied by Enamine Ltd., (Business ID Code
        30402329), having its registered address at 78 Winston Churchill Street,
        02094 Kyiv, Ukraine ("Enamine") and its distributors and Affiliates.
        These Terms of Use (the "Terms of Use") apply to the Enamine website
        located at www.enaminestore.com (hereinafter referred to as, the
        "Site"). The Site is the property of Enamine. By using the Site, you
        agree to these Terms of Use, if you do not agree, do not use the Site.
      </p>
      <p>
        1.2. Enamine reserves the right, at its sole discretion, to change,
        modify, add, or remove any provisions of these Terms of Use, at any time
        without notice. It is your responsibility to check these Terms of Use
        periodically for changes. If you continue to use the Site following the
        posting of changes, you accept and agree to the changes. 1.3. The entity
        you are contracting with is <b>SIA Enamine</b>, a limited liability
        company established under the laws of Latvia and having its principal
        place of business at Vestienas iela 2 B, LV-1035 Riga, Latvia if you
        reside in the European Union and Enamine US, Inc., a limited liability
        company established under the law of the state of New Jersey of the
        United States of America and having its principal place of business at 1
        Distribution Way, Monmouth Jct., NJ 08852, USA, if you reside in the
        United States of America or other countries of the world.
      </p>
      <Typography variant="h2">2. Definitions</Typography>
      <p>
        “Affiliate” means any company or person that directly, or indirectly, is
        controlled by or is under common control with Enamine; “control” and,
        with correlative meanings, the terms “controlled by” and “under common
        control with”, as used in this paragraph, shall mean the ownership,
        directly or indirectly, of at least fifty percent (50%) of the voting
        securities or other ownership interest of a company.
      </p>
      <p>
        "Catalogs" means whole Enamine, UkrOrgSyntez Ltd (UORSY), and FCH Group
        (FCH) catalogs and databases, or any selections made from these catalogs
        and requested by the purchaser including but not limited to online
        EnamineStore.com searches, requests sent by electronic mail, fax,
        regular post, or in oral form.
      </p>
      <p>
        "Confidential Information" means information of a proprietary nature
        including but not limited to individual prices, request(s) for
        quotation, purchase requisitions, purchase order information,
        user-to-user transactional information, the fee, individual proposals.
        “ORU” means any Purchaser’s research activity that may be performed,
        including research activity to obtain novel chemical matter derived from
        the use of Content.
      </p>
      <p>
        "Products" means structures, compounds including reagents for synthesis
        (Building Blocks) and screening compounds, libraries, selection of
        compounds, tools, kits, databases, collections, toolboxes, and
        highlights intended for laboratory use only, and offered for sale and
        sold by Enamine to Purchaser or distributed by Enamine free of charge,
        directly or via the Site.
      </p>
      <p>
        "Purchaser" means a registered or non-registered user who uses the Site
        including but not limited to purchase Products from Enamine or for the
        ORU.
      </p>
      <p>
        "Site" means Enamine websites to be found at{" "}
        <a href="https://enaminestore.com">https://enaminestore.com</a>,{" "}
        <a href="https://enaminestore.com">https://new.enaminestore.com</a>;
      </p>
      <p>
        "User" means a Purchaser registered via Enamine Site and may be referred
        to as "you", "your" in this Terms of Use.
      </p>
      <Typography variant="h2">3. Content</Typography>
      <p>
        3.1. All text, formulas, photographs, trademarks, logos, Catalog,
        Products (collectively, "Content"), including but not limited to the
        design, structure, selection, coordination, expression, and arrangement
        of such Content, contained on the Site is owned, controlled or licensed
        by or to Enamine, and is protected by copyright, patent and trademark
        laws, and various other intellectual property rights and unfair
        competition laws.
      </p>
      <p>
        3.2. Except as expressly provided in these Terms of Use, no part of the
        Site and no Content may be copied, reproduced, republished, uploaded,
        posted, publicly displayed, encoded, translated, transmitted, or
        distributed in any way to any other computer, server, web site or other
        medium for publication or distribution or for any commercial enterprise,
        without Enamine's express prior written consent.
      </p>
      <p>
        3.3. Unless otherwise specified in a valid agreement between Purchaser
        and Enamine Catalog shall be used only for information purpose or
        purchasing of compounds from Enamine.
      </p>
      <p>
        3.4. As a material condition of Enamine providing the Products to
        Purchaser, Purchaser shall not, directly or indirectly, use the Products
        for any purposes other than ORU and only then in accordance with the
        terms of these Terms and Conditions.
      </p>
      <p>
        3.5. Enamine acknowledges and agrees that the Purchaser’s use of the
        Products will include generating or creating compounds intended for
        commercialization, and Purchaser hereby is authorized by Enamine to
        utilize the Products for the purposes of generating a compound for sale
        and shall not be restricted from commercializing a product using the
        Products.
      </p>
      <p>
        3.6. Purchaser shall not (i) transfer for selling, or otherwise
        distribute the Products or any portion thereof to any third party,
        whether alone or in combination with other materials, except for such
        transfers as required for ORU, or (ii) sell the Products or any portion
        thereof to any third party, whether alone or in combination with other
        materials, or (iii) modify the Products for resale; (iv) use the
        Products to offer commercial services to third parties, without prior
        written approval from Enamine and subject to a separate royalty-bearing
        license agreement.
      </p>
      <p>
        3.7. The Products shall remain under full control of Purchaser during
        their whole lifetime and shall exclusively be used for ORU purposes.
      </p>
      <p>
        3.8. Disclaimer. The Products is intended for research and development
        laboratory use only, and, unless otherwise stated in this Terms and
        Conditions, is not to be used for any other purposes, including but not
        limited to in vitro diagnostic purposes, the uses in foods, drugs, or
        medical devices. Purchaser acknowledges that Supplier has not tested the
        Products for safety and efficacy in foods, drugs, or medical devices.
        The Products should be handled only by competent, suitably trained
        persons, familiar with laboratory procedures and potential chemical
        hazards. The Products are supplied under the condition that the
        Purchaser alone accepts total responsibility for its uses and any
        misuses and for the safe disposal of the Products. Purchaser has the
        responsibility to verify the hazards and to conduct any further research
        necessary to learn the hazards involved in using the Products. The
        Products shall not be foods, drugs, or medical devices, or their
        components.
      </p>
      <Typography variant="h2">
        4. Purchaser Account Registration and Password Reset
      </Typography>
      <p>
        4.1. Registration of the account is not required to search the catalog,
        browse through results, check compound details, and export structural
        data. You may make all the mentioned activity as a guest user. However,
        it is recommended to register and authorize while visiting
        www.enaminestore.com and www.enamine.net, as authorization enables
        saving your custom sets of compounds and provides access to libraries
        for offline use provided through enamine.net and enaminestore.com.
      </p>
      <p>
        4.2. To be able to process your order you should complete the User
        registration form and agree with these Terms of Use and related
        policies.
      </p>
      <p>
        4.3. Your Enamine account may be accessed only by use of your login and
        password. You are solely responsible and liable for any use and misuse
        of your login and password and for all activities that occur under your
        login and password. For security reasons, you must keep your login and
        password confidential and not disclose them to any person or permit any
        other person to use them, except an authorized representative of
        Enamine. Enamine recommends that you choose a password that is unique to
        you and not easily guessed by others. You must log out at the end of
        each session.
      </p>
      <p>
        4.4. If you have forgotten your login or password, you can use an easy
        password resetting procedure to retrieve login and a new password to
        your email address given in the process of registration. You can always
        change your personal data in the Profile section.
      </p>
      <p>
        4.5. While registering a new user we assume that the given email may be
        used as a primary electronic address. Consequently, we do not register
        any user with the same email. If you do not remember your registration
        information, please reset it using the Password Reset option. Upon
        resetting a password, a message with your login and a new password will
        be sent to your email.
      </p>
      <Typography variant="h2">5. Accuracy of Information</Typography>
      <p>
        We rely on the information you provide through this Site, including
        registration information (name and e-mail address) and
        transaction-related information (including but not limited to credit
        card numbers and expiration dates, shipping address, etc.), which must
        be true, accurate, current, and complete. You are required to update
        your registration information, if applicable, and will be solely
        responsible and liable for any and all loss, damage, and additional
        costs that you, we, or any other person may incur as a result of your
        submission of any false, incorrect or incomplete information. You
        acknowledge that you will keep your contact information current and
        accurate in your Enamine account section of the Site.
      </p>
      <Typography variant="h2">
        6. Product Price, Availability, Quantity, Quality and Order Limits
      </Typography>
      <p>
        6.1. Prices from the Enamine catalog are usually actual and up to date.
        Prices at UORSY and FCH catalog are provided as a reference only and
        must be confirmed by our managers within 3 (three) business days from
        the date of your order.
      </p>
      <p>
        6.2. Product prices and availability are subject to change at any time
        and without notice. Enamine may place a limit on the quantities that may
        be purchased per order, per account, per credit card, per purchaser.
      </p>
      <p>
        6.3. Enamine may refuse or reject any order at any time for reasons
        which include, but are not limited to, if you have not met the
        conditions specified at the time of the order, if the ordered products
        or services are not available, or for obvious errors on the Site or made
        in connection with your order. If we are unable to supply the products
        that you ordered, we will contact you and may offer you an alternative
        product to consider instead. If you do not choose to purchase the
        alternative product, we will cancel your order. In the event of obvious
        errors on the Site or made in connection with your order, we reserve the
        right to correct the error and charge you the correct price. In that
        situation, we will contact you and offer you the option of purchasing
        the product at the correct price or canceling your order.
      </p>
      <p>
        6.4. Quality of the product shall comply with the specifications set
        forth in the Catalog posted at the Site for a certain product.
      </p>

      <Typography variant="h2">7. Payment options</Typography>
      <p>
        7.1. The payment for the product shall be made within forty-five (45)
        days from the date the purchaser receives the product. In case of
        payment by way of credit card, the credit card shall be charged only
        after the delivery to the purchaser.
      </p>
      <p>
        7.2. If one or more products are unavailable, Enamine will notify the
        Purchaser, and the cost of unavailable and not dispatched products will
        be deducted from the total order.
      </p>
      <p>
        7.3. Different payment options are available for the Purchaser. The
        Purchaser can make the payment by credit card, wire transfer or
        cashier's check. Specific payment methods may be unavailable in a
        certain region. You should select the payment option available to your
        region during check-out procedure.
      </p>
      <p>
        7.4. If the Purchaser makes payment other than by a credit card, the
        purchaser has to have a valid purchase order issued and enter its number
        at the check-out stage, otherwise, processing of the order may be
        delayed.
      </p>
      <p>
        7.5. Enamine does not keep credit card numbers used for purchase. This
        information is transferred directly to the processing agency and cannot
        be accessed by Enamine.
      </p>
      <Typography variant="h2">
        8. Terms and Conditions for Discount Programs
      </Typography>
      <p>
        8.1. Promotional discounts are valid for online Purchases at Enamine
        only. Any reference to special sales conditions announced at Enamine
        will be rejected if orders are received by fax, e-mail, or post.
      </p>
      <p>
        8.2. Promotional discounts are subject to availability of compounds in
        stock. In particular, they are applied to Building Blocks which amount
        in stock exceeds 1 gram.
      </p>
      <p>
        8.3. Promotional discounts can only be applied to purchases of batches
        of 2.5 grams and smaller.
      </p>
      <p>
        8.4. Promotional discounts from different discount programs can NOT be
        summed up. By default, a larger discount will be applied replacing all
        other discounts.
      </p>
      <p>
        8.5. The discounts are valid in the period specified in the promotional
        program. After the end of the promotion, the discounts for new purchases
        will be automatically cancelled.
      </p>
      <p>
        8.6. Promotional programs are intended for the end users only. The
        discounts cannot be granted to Enamine partners and distributors, with
        whom Enamine has separate contracts in place. Nor are the discounts
        applicable to retailers and brokers.
      </p>
      <p>
        8.7. Enamine reserves the right to terminate the discounts in case of
        intentional abuse of the program by the customer.
      </p>
      <p>
        8.8. Enamine reserves the right to change the terms and conditions for
        any its promotional program without prior notice.
      </p>
      <p>
        8.9. Any discounts available at Enamine for building blocks are only
        applied to standard pack sizes: 0.1 g; 0.25 g; 0.5 g; 1.0 g; 2.5 g.
      </p>
      <Typography variant="h2">9. Refund and Cancellation</Typography>
      <p>
        9.1. If the quality of goods does not comply with the specification set
        forth by Enamine, Enamine shall refund 100 % of the cost of the product.
        The total amount of the refund shall not exceed the invoice price of the
        product. Enamine shall be indemnified from any damages, fees, penalties
        or any loss caused by the quality of the product or caused by late
        delivery of the product.
      </p>
      <p>
        9.2. If the Purchaser cancels the order, Enamine shall be entitled to
        charge a penalty in the amount of up to 100% of the cost of the product,
        subject to the terms of delivery by Enamine has been complied with. The
        amount of penalty shall be assessed based on the actual loss incurred by
        Enamine as a result of cancellation.
      </p>
      <p>
        9.3. Credits or refunds will be made to the same method of payment and
        account used to place the order.
      </p>
      <Typography variant="h2">10. Packaging and Delivery</Typography>
      <p>
        10.1. Products available in stock are usually delivered via FedEX, DHL
        or other courier service within 7 business days from the order date. The
        delivery of large sets of screening compound may take over 10 business
        days depending on the number of ordered compounds. The express delivery
        (up to 2 (two) business days) may be available for selected 1 g batches
        of building blocks in North America, EU countries, China and Japan.
      </p>
      <p>
        10.2. By default, screening compounds are formatted in 4 ml screw cap
        amber glass vials labeled with a barcode and a compound ID. It is
        possible to change the formatting to Matrix 96-well microtube plates
        from the shopping cart by using "Group update" option. Different
        formatting options for screening compounds are not supported within the
        same order. Custom formatting options can be requested at the checkout
        stage using "Order comments" form (additional charges may apply).
        Building blocks are provided in glass vials sealed with ParafilmTM
        sealing tape. Packaging is performed under inert gas atmosphere. All
        items are packaged in cardboard boxes lined with foam-rubber for
        shipping.
      </p>
      <p>
        10.3. Shipping and handling charges added to the order are indicated at
        the checkout procedure. The amount of these charges depends on the
        shipping destination.
      </p>
      <p>
        10.4. The Purchaser by its own discretion may specify own delivery
        account number with particular courier service company in its profile.
      </p>
      <p>
        10.5. You indemnify Enamine for any late delivery of the product, if the
        product was dispatched by Enamine to the courier company on time, as
        well as for loss and damage of the product by such courier company.
      </p>
      <Typography variant="h2">11. Taxes</Typography>
      <p>
        11.1. Prices shown on the Site exclude all taxes or charges ("Taxes")
        that may apply to your purchase. Prices shown on the Site also exclude
        delivery costs. Taxes and delivery costs will be added to the amount of
        your purchase and shown on the check-out page. You will have an
        opportunity to review the taxes and delivery costs before you confirm
        your purchase.
      </p>
      <p>
        11.2. The recipient of an international shipment may be subject to
        import duties and taxes, which are levied once a shipment reaches the
        destination country. For all orders, the purchaser is fully responsible
        for paying any taxes, customs duties, and/or associated charges for
        customs clearance. The purchaser takes full responsibility to comply
        with local rules and regulations applicable to ordered chemicals,
        including but not limited to safe and environmentally friendly handling
        and disposal.
      </p>
      <Typography variant="h2">12. Confidential information</Typography>
      <p>
        You undertake treat and maintain as confidential all Confidential
        Information, which may only be used for the purposes of fulfilling your
        obligations under these Terms of Use. Notwithstanding the above, you may
        disclose Confidential Information pursuant to a valid court order or
        where the Confidential Information is already in the public domain
        through no fault of you.
      </p>
      <Typography variant="h2">13. Privacy and use of Cookies</Typography>
      <p>
        Our <a href="/privacy-policy">Privacy Policy</a> and Cookies Policy
        applies to use of this Site, and their terms make a part of this Terms
        of Use by reference. Additionally, by using the Site, you acknowledge
        and agree that Internet transmissions are never completely private or
        secure. You understand that any message or information you send to the
        Site may be read or intercepted by others, even if there is a special
        notice that a particular transmission (for example, credit card
        information) is encrypted.
      </p>
      <Typography variant="h2">
        14. DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY
      </Typography>
      <p>
        14.1. WE TRY TO KEEP SITE, SERVICES, APPLICATIONS, AND TOOLS SAFE,
        SECURE, AND FUNCTIONING PROPERLY. YOU ACKNOWLEDGE THAT WE CANNOT
        GUARANTEE THE CONTINUOUS OPERATION OF OR ACCESS TO OUR SITE, SERVICES,
        APPLICATIONS OR TOOLS. YOU FURTHER ACKNOWLEDGE THAT OPERATION OF AND
        ACCESS TO OUR SITE, SERVICES, APPLICATIONS OR TOOLS MAY BE INTERFERED
        WITH AS A RESULT OF TECHNICAL ISSUES OR NUMEROUS FACTORS OUTSIDE OF OUR
        CONTROL. UPDATES AND OTHER NOTIFICATION FUNCTIONALITY IN APPLICATIONS
        MAY NOT OCCUR IN REAL TIME. SUCH
      </p>
    </div>
  );
};

export default TermsOfUseContent;
