import styled from "styled-components";

export const Wrapper = styled.div`
  max-width: 1004px;
  margin: 0 auto;
  text-align: center;
  padding: 100px 0 16px 0;
`;

export const LogoWrapper = styled.div`
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 100%;
    width: auto;
  }
`;
