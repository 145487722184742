import { SearchAsTypes, SearchAsTypesV2 } from "constants/searchAsTypes";
import { defaultAmountsAndMeasures } from "../../../../constants";
import convertCatalogToCat from "../../../../utils/convetCatalogToCat";
import { SearchByTypes } from "pages/home/parts/search-form/SearchForm.types";

export const prepareList = (list) => {
  return list.map(({ product, prices }) => {
    const defaultAmountAndMeasure =
      defaultAmountsAndMeasures[convertCatalogToCat(product?.catalog)];

    return {
      productType: convertCatalogToCat(product?.catalog),
      code: product?.code,
      catalog: product?.catalog,
      quantity: 1,
      amount: defaultAmountAndMeasure.amount,
      measure: defaultAmountAndMeasure.measure,
      mode: "Add",
      cdId: product?.cdId,
      stock: product?.stock,
      smile: product?.smile,
      name: product?.name,

      product,
      prices: prices,
    };
  });
};

export const getSearchType = (
  type?: string,
  searchBy?: SearchByTypes,
  sstype?: SearchAsTypes
) => {
  switch (true) {
    case !type && !searchBy:
      return "BY_CODES";

    case !type && searchBy === SearchByTypes.MFCDs:
      return "BY_MFCDS";

    case !type && searchBy === SearchByTypes.CASs:
      return "BY_CASES";

    case type && sstype && !searchBy:
      return `BY_SMILES_${SearchAsTypesV2[sstype]}`;

    default:
      return null;
  }
};
