import { FormikProps } from "formik";
import { FormikValues } from "formik/dist/types";
import React from "react";
import FromToInput from "../../../../ui/FromToInput/FromToInput";
import {
  ErrorMessageContainer,
  FilterContainer,
  FilterDividerWrapper,
  FilterInputContainer,
  FilterInputsContainer,
  FilterInputsWrapper,
  FilterLabel,
} from "./FilterFromTo.styles";

type FilterFromToProps = {
  formik: FormikProps<FormikValues>;
  fieldName: string;
  label?: string;
  inputHeight?: string;
  placeholderFrom?: string;
  placeholderTo?: string;
};
const FilterFromTo = ({
  formik,
  fieldName,
  label,
  inputHeight,
  placeholderFrom,
  placeholderTo,
}: FilterFromToProps) => {
  const hasError =
    formik?.touched[fieldName] && Boolean(formik?.errors[fieldName]);
  const errorMessage = formik?.touched[fieldName] && formik?.errors[fieldName];
  return (
    <FilterContainer>
      {label && <FilterLabel> {label}</FilterLabel>}
      <FilterInputsContainer>
        <FilterInputsWrapper>
          <FilterInputContainer>
            <FromToInput
              formik={formik}
              name={fieldName}
              subName="from"
              placeholder={placeholderFrom || "From"}
              type="number"
              step="0.01"
              inputHeight={inputHeight}
              hideErrorMessage
            />
          </FilterInputContainer>

          <FilterDividerWrapper>—</FilterDividerWrapper>

          <FilterInputContainer>
            <FromToInput
              formik={formik}
              name={fieldName}
              subName="to"
              placeholder={placeholderTo || "To"}
              type="number"
              step="0.01"
              inputHeight={inputHeight}
              hideErrorMessage
            />
          </FilterInputContainer>
        </FilterInputsWrapper>
        {hasError && (
          <ErrorMessageContainer>
            {errorMessage?.from || errorMessage?.to}
          </ErrorMessageContainer>
        )}
      </FilterInputsContainer>
    </FilterContainer>
  );
};

export default FilterFromTo;
