import { Typography } from "@mui/material";
import styled from "styled-components";
import { borderColor, darkBlue } from "ui/colors";
import { NotFoundIcon } from "ui/icons";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 0;
`;

export const NotFoundImage = styled(NotFoundIcon)`
  width: 180px;
  height: 180px;
  margin-bottom: 16px;
`;

export const Title = styled(Typography)`
  margin-bottom: 60px;
  color: ${borderColor};
`;

export const Description = styled(Typography)`
  max-width: 601px;
  margin-bottom: 36px;
  padding: "0px 8px";
  color: ${darkBlue};
`;
