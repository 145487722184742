export enum FilterTypes {
  sstype = "sstype",
  category = "category",
  LOCATION = "location",
  classes = "classes",
  subclasses = "subclasses",
  MW = "MW",
  CLogP = "CLogP",
  HBA = "HBA",
  HBD = "HBD",
  RotB = "RotB",
  TPSA = "TPSA",
  HAC = "HAC",
  Fsp3 = "Fsp3",
  collections = "collections",
  similarity = "similarity",
}

export enum SearchStatus {
  FIND_COMPOUNDS_STARTED = "Searching",
  FIND_COMPOUNDS_COMPLETED = "Getting Products",
  FIND_COMPOUNDS_PRICES_COMPLETED = "Getting Prices",
  FIND_COMPOUNDS_INFO_COMPLETED = "Loading Results",
}
