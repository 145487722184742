import styled from "styled-components";
import { lineColor } from "../../../../../ui/colors";

export const OrderItem = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${lineColor};
  width: 100%;
  &:last-child {
    border-bottom: 0;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  & > img {
    width: 100px;
    height: auto;
  }
`;
