import styled from "styled-components";
import { accentColor, borderColor, darkBlue } from "ui/colors";

export const List = styled.ul`
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  min-height: 48px;
`;

export const ListItem = styled.li`
  display: flex;
  padding: 0;
  font-size: 14px;
  font-weight: 400;

  &:not(:last-child):after {
    content: "/";
    color: ${borderColor};
    margin-left: 8px;
    margin-right: 6px;
  }

  & a {
    text-decoration: none !important;
    color: ${darkBlue};
  }
`;

export const InactiveLink = styled.span`
  color: ${accentColor};
  pointer-events: none;
`;
