import { FullWidthLoader } from "parts";
import { Button, EmptyMessage, Flex, Paper, Table, Title } from "ui";
import UploadModal from "./UploadModal/UploadModal";
import React, { Dispatch, SetStateAction } from "react";

type TaxFreeCertificatesProps = {
  items: any[];
  loading: boolean;
  handleUploadModalClick: VoidFunction;
  handleChange: (file: File) => Promise<void>;
  modal: {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
  };
};

const TaxFreeCertificates = ({
  items,
  loading,
  handleUploadModalClick,
  handleChange,
  modal,
}: TaxFreeCertificatesProps) => {
  return (
    <>
      <Paper>
        <Flex type="center-between" marginBottom>
          <Title>Tax Exempt Certificates</Title>
          <Button
            type="outlined"
            size="small"
            onClick={handleUploadModalClick}
            dataTestAttr="upload-certificate-button"
          >
            Upload Certificate
          </Button>
        </Flex>
        {loading ? (
          <FullWidthLoader />
        ) : (
          <>
            {items?.length ? (
              <Table variant="bordered">
                {items.map((el) => {
                  return (
                    <Table.Row key={el.uuid}>
                      <Table.Cell verticalCenter>
                        <Title variant="h3">{el.name}</Title>
                      </Table.Cell>
                      <Table.Cell title="Status">{el.status}</Table.Cell>
                      <Table.Cell title="Upload Date">{el.uploaded}</Table.Cell>
                      <Table.Cell title="Valid to">{el.validTo}</Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table>
            ) : (
              <EmptyMessage>
                You haven’t uploaded any tax exempt certificates yet.
              </EmptyMessage>
            )}
          </>
        )}
      </Paper>
      <UploadModal
        open={modal.open}
        setOpen={modal.setOpen}
        handleChange={handleChange}
      />
    </>
  );
};

export default TaxFreeCertificates;
