import React from "react";
import { useDispatch } from "react-redux";
import { checkoutActions } from "../../../redux/checkout";

import Accordion from "./CheckoutAccordion";

const CheckoutAccordionContainer = ({ items, modeRegulator, ...rest }) => {
  const dispatch = useDispatch();

  const handleEdit = (id) => {
    dispatch(checkoutActions.openAccordion(id));
  };

  return (
    <Accordion
      items={items}
      handleEdit={handleEdit}
      modeRegulator={modeRegulator}
      {...rest}
    />
  );
};

export default CheckoutAccordionContainer;
