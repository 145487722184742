import React from "react";
import { Button } from "ui";
import { CartIcon, FileDownloadIcon, SearchIcon } from "ui/icons";
import { Wrapper } from "./ActionBar.styled";

type ActionBarProps = {
  isLoadingAllToCart: boolean;
  isLoadingSdf: boolean;
  isNothingFound: boolean;
  backToSearchHref: string;
  onHandleAllToCart: () => Promise<void>;
  onHandleDownloadSdf: () => Promise<void>;
};

export const ActionBar = ({
  onHandleAllToCart,
  isLoadingAllToCart,
  onHandleDownloadSdf,
  isLoadingSdf,
  isNothingFound,
  backToSearchHref,
}: ActionBarProps) => (
  <Wrapper>
    {isNothingFound ? (
      <Button
        iconLeft={<SearchIcon />}
        type="outlined-white"
        size="small"
        href={backToSearchHref}
      >
        Back to Search
      </Button>
    ) : (
      <>
        <Button
          iconLeft={<CartIcon />}
          type="outlined-white"
          size="small"
          onClick={onHandleAllToCart}
          isLoading={isLoadingAllToCart}
        >
          All to cart
        </Button>
        <Button
          iconLeft={<FileDownloadIcon />}
          type="outlined-white"
          size="small"
          onClick={onHandleDownloadSdf}
          isLoading={isLoadingSdf}
        >
          Save as SD file
        </Button>
      </>
    )}
  </Wrapper>
);
