import React from "react";
import { useParams } from "react-router-dom";
import useBackToOrdersLink from "./hooks/useBackToOrdersLink";
import useFetchOrderWithDetails from "./hooks/useFetchOrderWithDetails";
import Order from "./Order";

const OrderContainer = () => {
  const { id: orderId } = useParams<{ id: string }>();
  const backToOrdersLink = useBackToOrdersLink();
  const order = useFetchOrderWithDetails({ orderId });

  return (
    <Order
      backToOrdersLink={backToOrdersLink}
      loading={order?.loading}
      details={order?.data?.details}
      items={order?.data?.items}
      orderId={orderId}
    />
  );
};

export default OrderContainer;
