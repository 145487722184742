import { SearchAsTypes } from "constants/searchAsTypes";
import React from "react";
import { getArrayFromBulkSearch } from "utils";
import CatalogFilters from "../../components/CatalogFilters/CatalogFilters";
import SidebarHeader, { SearchType } from "../sidebar-header/SidebarHeader";
import { SearchSubSection, SearchTitle, Wrapper } from "./Sidebar.styled";

type SidebarProps = {
  q: string;
  drawSearch: string;
  sstype: SearchAsTypes;
  cat: string;
};

const Sidebar = ({ q, drawSearch, sstype, cat }: SidebarProps) => {
  const items = getArrayFromBulkSearch(q);

  const searchType = drawSearch
    ? SearchType.STRUCTURE
    : items.length === 1
    ? SearchType.PHRASE
    : SearchType.BULK;

  return (
    <Wrapper>
      <SearchTitle>
        {searchType === SearchType.STRUCTURE ? "Filters" : "Search"}
      </SearchTitle>
      <SearchSubSection>
        <SidebarHeader items={items} structure={q} type={searchType} />
      </SearchSubSection>
      <CatalogFilters sstype={sstype} cat={cat} drawSearch={drawSearch} />
    </Wrapper>
  );
};

export default Sidebar;
