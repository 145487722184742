import { Typography } from "@mui/material";
import styled from "styled-components";
import { darkBlue, lineColor } from "ui/colors";
import Spinner from "ui/spinner/Spinner";

export const Page = styled.div`
  padding-right: 20px;
  padding-left: 20px;
`;

export const List = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${(props) => {
    if (props.hasFooter) {
      return `padding-bottom: 16px;`;
    }
  }}
`;

export const ListItem = styled.div`
  width: 33.33333%;
  box-sizing: border-box;

  ${(props) => {
    if (props.fullWidth) {
      return `
        width: 100%;
        border: none;
        &:not(:last-of-type) {
          border-bottom: 1px solid ${lineColor}
        }
      `;
    } else {
      return `
        border-right: 1px solid ${lineColor};
        border-bottom: 1px solid ${lineColor};

        &:nth-of-type(3n) {
          border-right: none;
        }

        &:nth-last-of-type(-n + 3):nth-of-type(3n + 1),
        &:nth-last-of-type(-n + 2):nth-of-type(3n + 2),
        &:nth-last-of-type(-n + 1):nth-of-type(3n + 3) {
          border-bottom: none;
        }

        &:nth-last-of-type(-n + 3):nth-of-type(3n + 1) {
          border-bottom-left-radius: 16px;
        }

        &:nth-last-of-type(-n + 1):nth-of-type(3n + 3) {
          border-bottom-right-radius: 16px;
        }
      `;
    }
  }}
`;

export const Group = styled.div`
  display: flex;
  padding-bottom: 150px;
  align-items: flex-start;
  column-gap: 32px;
`;
Group.displayName = "Group";

export const Paper = styled.div`
  border-radius: 16px;
  background-color: white;
  display: flex;
  flex-direction: column;
  ${(props) => {
    if (props.hasMinHeight) {
      return `min-height: 416px`;
    }
  }}
`;
Paper.displayName = "Paper";

export const PaginationWrapper = styled.div`
  padding: 32px 24px 24px;
  border-top: 1px solid ${lineColor};
`;

export const GroupItem = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  ${(props) => {
    if (props.main) {
      return `
        width: 100%;
        min-height: 416px;
      `;
    }
  }}
`;

export const CatalogSpinner = styled(Spinner)`
  flex-grow: 1;
  padding-bottom: 80px;
`;

export const Title = styled(Typography)`
  line-height: 48px;
  color: ${darkBlue};
`;
