import { useEffect, useState } from "react";
import { fetchImage } from "redux/util/api";
import { customFetch } from "utils";
import { ProductTypesV2 } from "../../../../../constants/productTypes";
import { RequestMethods } from "../../../../../utils/customFetch";
import { OrderItem } from "../Order.types";
import { endpoints } from "constants";

const useFetchOrderItems = (orderId: string) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await fetchOrderItems(orderId);

      if (res[0]) {
        const uniqueCodes = getUniqueCodesFromOrderItemsResponseData(res[0]);
        const images = await fetchImages(uniqueCodes);

        const items = res[0].map((item) => {
          const image = images.find(
            (el) =>
              el.code === item.code &&
              el.catalog === ProductTypesV2[item.productType]
          );

          return {
            ...item,
            image: image?.url || "/not-found-product.png",
          };
        });

        setData(items);
      }

      setLoading(false);
    })();
  }, [orderId]);

  return { loading, data };
};

export async function fetchImages(
  codesWithCatalogs: { catalog: ProductTypesV2; code: string }[]
) {
  const requests = [];
  const codes = [];
  const newImages = [];

  if (codesWithCatalogs?.length) {
    codesWithCatalogs.forEach((el) => {
      requests.push(
        fetchImage({
          code: el.code,
          catalog: el.catalog,
        })
      );
      codes.push({
        code: el.code,
        catalog: el.catalog,
      });
    });
  }

  return Promise.all(requests).then((data) => {
    data.forEach(([res, err], idx) => {
      newImages.push({
        url: res?.image || "/not-found-product.png",
        ...codes[idx],
      });
    });

    return newImages;
  });
}

export const fetchOrderItems = (orderId: string) => {
  return customFetch({
    url: `${endpoints.ORDERS_ORDER_CONTENT}?id=${orderId}`,
    method: RequestMethods.POST,
  });
};

export const getUniqueCodesFromOrderItemsResponseData = (
  responseData: OrderItem[]
): { catalog: ProductTypesV2; code: string }[] => {
  const codes = {};
  const uniqueCodes = [];

  responseData.forEach((el) => {
    const fieldName = `${el.code}_${el.productType}`;
    codes[fieldName] = {
      code: el.code,
      catalog: ProductTypesV2[el.productType],
    };
  });

  Object.keys(codes).forEach((code) => {
    uniqueCodes.push(codes[code]);
  });

  return uniqueCodes;
};

export default useFetchOrderItems;
