import animateScrollTo from "animated-scroll-to";
import { customFetchDelayedMiddleware, SSEContext } from "context/sseContext";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { currencySelectors } from "redux/currency";
import { events, useAmplitudeState } from "../../context/amplitude-context";
import useAllToCart from "../../features/super-cart/hooks/useAllToCart";
import { useDownloadSdf, usePagination, useQuery } from "../../hooks";
import { catalogActions, catalogSelectors } from "../../redux/catalog";
import { utilSelectors } from "../../redux/util";
import { encode, makeParams } from "../../utils";
import Catalog from "./Catalog";
import { SearchStatus } from "./types";
import { endpoints } from "constants";
import { RequestMethods } from "utils/customFetch";

export const listErrorTypes = {
  NOT_FOUND: "NOT_FOUND",
  DISABLED: "DISABLED",
};

const CatalogContainer = () => {
  const { amplitude } = useAmplitudeState();
  const query = useQuery();
  const { id = "", path1, path2, catalog } = useParams();
  const [q, setQ] = useState(query.get("q") || "");
  const [listName, setListName] = useState("");
  const [listErrorType, setListErrorType] = useState("");
  const type = query.get("type");
  const cat = query.get("cat");
  const sstype = query.get("sstype");
  const searchBy = query.get("searchBy");
  const drawSearch = query.get("drawSearch");
  const curPage = parseInt(query.get("curPage")) || 1;
  const dispatch = useDispatch();
  const history = useHistory();
  const currency = useSelector(currencySelectors.selectCurrent);
  const isLoading = useSelector(catalogSelectors.selectLoadingItems);
  const catalogFilters = useSelector(catalogSelectors.selectCatalogFilters);
  const pageSize = useSelector(catalogSelectors.selectPageSize);
  const viewMode = useSelector(catalogSelectors.selectViewMode);
  const images = useSelector(utilSelectors.selectImages);
  const storeKey = `${q && id === "" ? q.toString() : id}_${sstype}_${cat}`;
  const { searchState, restartSSEConnection } = useContext(SSEContext);
  const { pathname } = useLocation();

  const catalogItemsWithPrices = useSelector((state) =>
    catalogSelectors.selectCatalogItemsWithPrices(state, storeKey)
  );

  const [total, setTotal] = useState(0);
  const [isNothingFound, setNothingFound] = useState(false);

  const logDownloadSdf = () => {
    amplitude.logEvent(events.donwload_sdf_click);
  };

  // @TODO Check pagination to handle it on FE side
  const [pagination] = usePagination({
    total,
    pageSize,
    curPage,
  });

  const { isLoading: isLoadingSdf, handleDownload: handleDownloadSdf } =
    useDownloadSdf({
      drawSearch,
      q,
      searchType: type,
      productType: cat,
      sstype,
      logDownloadSdf,
      similarityThreshold: catalogFilters?.similarity,
    });

  const { handleAllToCart, isLoading: isLoadingAllToCart } = useAllToCart({
    total,
    code: encode(q),
    searchedType: type,
    cat,
    list: catalogItemsWithPrices,
    searchBy: searchBy,
    catalogFilters,
  });

  const onHandleSetPageSize = (size) => {
    amplitude.logEvent(events.show_by, { number: size });
    dispatch(catalogActions.setPageSize({ curPage, size, storeKey }));
    updateQueryParamsWithPage(1);
  };

  const onHandleSetViewMode = (mode) => {
    amplitude.logEvent(events.change_view_click, { type: mode });
    dispatch(catalogActions.setViewMode(mode));
  };

  const onHandleClickPagination = (page) => {
    amplitude.logEvent(events.pagination_click, { number: page });
    animateScrollTo(0);
    updateQueryParamsWithPage(page);
    dispatch(catalogActions.setPageSize({ curPage: page, storeKey }));
  };

  const updateQueryParamsWithPage = (page) => {
    let queryParams = "";

    if (id) {
      queryParams = makeParams({
        curPage: page,
      });

      history.push(`/product-lists/${id}?${queryParams}`);
    } else {
      queryParams = makeParams({
        q: encode(q),
        cat,
        type,
        sstype,
        drawSearch,
        curPage: page,
      });

      history.push(`/catalog?${queryParams}`);
    }
  };

  const fetchMoreData = () => {
    dispatch(
      catalogActions.requestProductsWithPrices({
        code: encode(q),
        type,
        cat,
        sstype,
        storeKey,
        curPage,
        setNothingFound,
        setTotal,
        pageSize,
        searchBy,
      })
    );
  };

  const getProductList = () => {
    dispatch(
      catalogActions.getProductList({
        id,
        storeKey,
        setQ,
        setListName,
        setListErrorType,
        curPage,
        pageSize,
        setTotal,
      })
    );
  };

  useEffect(() => {
    restartSSEConnection();
    if (drawSearch) {
      if (sstype && cat) {
        fetchMoreData();
      }
    } else if (id === "" && q !== "") {
      fetchMoreData();
    } else if (id !== "") {
      getProductList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sstype, cat, currency, catalogFilters, q]);

  useEffect(() => {
    const fetchCatalog = async () => {
      const finalPath = `${path1}/${path2}/${catalog}`;

      const response = await customFetchDelayedMiddleware({
        url: `${endpoints.CATALOG_CATEGORIES_BY_PATH}/${finalPath}`,
        method: RequestMethods.GET,
      });

      if (response[0]) {
        const productCodes = response[0].products.map(({ code }) => code);
        if (!pathname.includes("/catalog")) {
          const query = `${pathname}/catalog?q=${encode(productCodes)}`;
          setQ(productCodes.join(","));
          history.push(query);
        }
      }
    };

    if (catalog && q === "") {
      fetchCatalog();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Catalog
      items={catalogItemsWithPrices}
      images={images}
      isLoading={isLoading}
      encoded={encode(q)}
      q={q}
      id={id}
      sstype={sstype}
      cat={cat}
      drawSearch={drawSearch}
      isNothingFound={isNothingFound}
      onHandleSetPageSize={onHandleSetPageSize}
      onHandleSetViewMode={onHandleSetViewMode}
      onHandleDownloadSdf={handleDownloadSdf}
      isLoadingSdf={isLoadingSdf}
      pageSize={pageSize}
      viewMode={viewMode}
      pagination={pagination}
      onHandleClickPagination={onHandleClickPagination}
      onHandleAllToCart={handleAllToCart}
      isLoadingAllToCart={isLoadingAllToCart}
      listName={listName}
      listErrorType={listErrorType}
      completionState={SearchStatus[searchState.completionState] + "..."}
      completionPercent={searchState.completionPercent}
    />
  );
};

export default CatalogContainer;
