import React from "react";
import { Switch, Route } from "react-router-dom";

import ProfilePage from "./pages/profile/Profile";
import HomePage from "./pages/home/Home";
import CatalogPage from "./pages/catalog/Catalog.container";
import CheckoutPage from "./pages/checkout/Checkout.container";
import SignInPage from "./pages/SignIn/SignIn";
import SignUpPage from "./pages/sign-up/SignUp";
import ProductPage from "./pages/product/Product.container";
import NotFoundPage from "./pages/not-found/NotFound";
import DrawSearchPage from "./pages/DrawSearch/DrawSearch";
import ResetPasswordPage from "./pages/reset-password/ResetPassword";
import TermsOfUsePage from "./pages/TermsOfUse/TermsOfUse";
import PrivacyPolicyPage from "./pages/privacy-policy/PrivacyPolicy";
import { DocumentationRoutes } from "pages/apidoc/DocumentationRoutes";
import SubmitPaymentForm from "./pages/submit-payment-form/SubmitPaymentForm";
import PaymentStatus from "./pages/payment-status/PaymentStatus.container";
import Ui from "./pages/ui/Ui";

import PrivateRoute from "./parts/private-route/PrivateRoute";

import rootPages from "./constants/rootPages";
import { ErrorBoundary } from "./parts";
import { useCatchNewUser } from "./hooks";
import { Main } from "./layouts";
import RedirectToOldStorePageContainer from "features/onboarding/redirect-to-old-store-page";
import CartPageContainer from "features/super-cart/CartPage/CartPageContainer/CartPageContainer";
import SubCategory from "./pages/SubCategory/SubCategory";
import OrderContainer from "./pages/profile/pages/Order/Order.container";

const Routes = () => {
  useCatchNewUser();

  return (
    <Switch>
      <Route path={`/ui`}>
        <Main>
          <Ui />
        </Main>
      </Route>

      <Route exact path={["/", `/${rootPages.SEARCH}`]}>
        <Main>
          <HomePage />
        </Main>
      </Route>

      <Route path={`/${rootPages.DRAW_SEARCH}`}>
        <Main>
          <DrawSearchPage />
        </Main>
      </Route>

      <Route path={`/${rootPages.SIGNIN}`}>
        <Main filled>
          <SignInPage />
        </Main>
      </Route>

      <Route exact path={`/categories/:path1/:path2?`}>
        <Main filled>
          <SubCategory />
        </Main>
      </Route>

      <Route path={`/categories/:path1/:path2?/:catalog`}>
        <Main filled>
          <CatalogPage />
        </Main>
      </Route>

      <Route path={`/${rootPages.SIGNUP}`}>
        <Main filled>
          <SignUpPage />
        </Main>
      </Route>

      <PrivateRoute path={`/${rootPages.CHECKOUT}`}>
        <Main filled>
          <CheckoutPage />
        </Main>
      </PrivateRoute>

      <Route path={`/${rootPages.RESET_PASSWORD}/:token?`}>
        <Main filled>
          <ResetPasswordPage />
        </Main>
      </Route>

      <Route path={`/${rootPages.SUBMIT_PAYMENT_FORM}`}>
        <Main filled>
          <SubmitPaymentForm />
        </Main>
      </Route>

      <Route path={`/${rootPages.PAYMENT_STATUS}`}>
        <Main filled>
          <PaymentStatus />
        </Main>
      </Route>

      <Route path="/product-lists/:id">
        <Main filled>
          <CatalogPage />
        </Main>
      </Route>

      <Route path={`/${rootPages.REDIRECT_TO_OLD_STORE}`}>
        <Main filled>
          <RedirectToOldStorePageContainer />
        </Main>
      </Route>

      <Route path="/catalog/:id">
        <Main>
          <ProductPage />
        </Main>
      </Route>

      <Route path="/catalog">
        <Main filled>
          <CatalogPage />
        </Main>
      </Route>

      <Route path={`/${rootPages.CART}`}>
        <Main filled>
          <ErrorBoundary>
            <CartPageContainer />
          </ErrorBoundary>
        </Main>
      </Route>

      <PrivateRoute path={`/${rootPages.PROFILE}/orders/:id`}>
        <Main filled>
          <OrderContainer />
        </Main>
      </PrivateRoute>

      <PrivateRoute path={`/${rootPages.PROFILE}`}>
        <Main filled>
          <ProfilePage />
        </Main>
      </PrivateRoute>

      <Route path="/terms-of-use">
        <Main>
          <TermsOfUsePage />
        </Main>
      </Route>

      <Route path="/privacy-policy">
        <Main>
          <PrivacyPolicyPage />
        </Main>
      </Route>

      <PrivateRoute path="/documentation">
        <Main>
          <ErrorBoundary>
            <DocumentationRoutes />
          </ErrorBoundary>
        </Main>
      </PrivateRoute>

      <Route path="*">
        <Main filled>
          <NotFoundPage />
        </Main>
      </Route>
    </Switch>
  );
};

export default Routes;
