import styled from "styled-components";

import { Button as ButtonComponent } from "../../../ui";
import { Typography } from "@mui/material";

export const Content = styled.div`
  padding: 0 24px;
`;

export const Wrapper = styled.div`
  background: #ffffff;
  border-radius: 16px;
  min-width: 360px;
`;

export const Scrollable = styled.div`
  max-height: 500px;
  overflow-y: auto;
`;

export const Button = styled(ButtonComponent)`
  width: 100%;
  margin-top: 20px;
`;

export const Footer = styled.div`
  margin-top: 10px;
  padding-right: 25px;
  padding-left: 25px;
  padding-bottom: 25px;
`;

export const List = styled.div``;

export const ListItem = styled.div``;

export const Title = styled(Typography)`
  margin: 0;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 22px 15px 22px;
`;
