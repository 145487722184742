import styled from "styled-components";
import {
  accentColor,
  blue,
  borderColor,
  dangerColor,
  darkBlue,
} from "../colors";

export const Wrapper = styled.div`
  &.input {
    width: 100%;
    box-sizing: border-box;
    position: relative;

    &.is-disabled {
      .input__input {
        color: ${blue};
        background-color: #f6f7f8;
      }
    }

    &.is-error {
      .input__input {
        border-color: ${dangerColor};

        &::placeholder {
          color: ${borderColor};
        }
      }
    }
  }

  .input__input {
    height: 48px;
    border: 1px solid ${borderColor};
    width: 100%;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 0 16px;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    outline: none;
    color: ${darkBlue};

    &:hover:not(:disabled) {
      border-color: ${accentColor};
    }

    &:focus:not(:disabled) {
      border-color: ${accentColor};
    }

    &::placeholder {
      opacity: 1;
      color: ${borderColor};
    }
  }

  .input__label {
    font-size: 14px;
    margin-bottom: 6px;
    display: inline-block;
  }

  .input__error-message {
    color: ${dangerColor};
    margin: 0;
    margin-top: 4px;
    font-size: 14px;
  }
`;

export const IconWrapper = styled.span`
  position: absolute;
  top: 50%;
  left: 18px;
  transform: translateY(-50%);
  pointer-events: none;
`;
