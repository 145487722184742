import { Link, Tag } from "ui";
import { convertToDateAndTime, mapOrderStatus } from "utils";
import { getTagTypeBasedOnOrderStatus } from "../../orders/utils";
import React from "react";
import {
  OrderHeaderWrapper,
  OrderTags,
  OrderTitleWrapper,
} from "./OrderHeader.styled";
import { Typography } from "@mui/material";

type OrderHeaderProps = {
  backToOrdersLink: string;
  loading: boolean;
  orderId: string;
  date: string;
  type: string;
  status: string;
};

const OrderHeader = ({
  backToOrdersLink,
  loading,
  orderId,
  date,
  type,
  status,
}: OrderHeaderProps) => {
  const isQuote = type === "QUOTE";
  return (
    <>
      <OrderHeaderWrapper>
        <Link
          className="order-page__back-link"
          to={backToOrdersLink}
          disableDecoration
          data-test="back-to-orders-link"
        >
          Back to orders
        </Link>
        <OrderTitleWrapper>
          <Typography variant="h1">
            {isQuote ? "Quote" : "Order"} #
            <span data-test="order-id">{orderId}</span>
          </Typography>

          {!loading && (
            <OrderTags>
              <Tag>{type}</Tag>
              <Tag type={getTagTypeBasedOnOrderStatus(status)}>
                {mapOrderStatus(status)}
              </Tag>
            </OrderTags>
          )}
        </OrderTitleWrapper>
        <div>{!loading && <>{convertToDateAndTime(date)}</>}</div>
      </OrderHeaderWrapper>
    </>
  );
};

export default OrderHeader;
