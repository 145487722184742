import { endpoints } from "constants";
import customFetch, { RequestMethods } from "utils/customFetch";
import { toastNoCartModify } from "../../../utils";

export async function fetchCartInfo(currency) {
  let url = endpoints.CART_INFO;

  if (currency) {
    url += "?cur=" + currency;
  }
  return customFetch({
    url,
  });
}

export const postCartContent = ({ items, currency }) => {
  return customFetch({
    method: "post",
    url: `${endpoints.CART_CONTENT}`,
    data: {
      currency,
      products: items,
    },
  });
};

export async function fetchItems(currency) {
  const res = await customFetch({
    url: endpoints.CART_CONTENT + `?currency=${currency || "USD"}`,
  });

  const newItems = res[0]
    ? res[0]?.data.map((item) => {
        return {
          ...item,
          currency: res[0]?.currency,
        };
      })
    : [];

  return newItems;
}

export function allToCart({ catalog, searchType, data }) {
  return customFetch({
    method: RequestMethods.POST,
    url: endpoints.CART_ALL_TO_CART,
    data,
    config: {
      params: {
        catalog,
        searchType,
      },
    },
  });
}

// @TODO Recheck logic from prev DEV and write code in a proper way with error handling
export async function removeItem(props) {
  const { cdId, id, code } = props;

  return await customFetch({
    url: endpoints.CART_CONTENT,
    method: "post",
    data: {
      currency: "USD",
      products: [
        {
          productType: "SCR",
          code,
          quantity: 0,
          amount: 0,
          measure: "g",
          mode: "Del",
          id,
          cdId,
        },
      ],
    },
  });
}

// @TODO Recheck logic from prev DEV and write code in a proper way with error handling
export async function updateCount(props) {
  const {
    cdId,
    id,
    code,
    quantity,
    amount,
    measure,
    productType,
    currency,
    formula,
  } = props;

  try {
    const res = await customFetch({
      url: endpoints.CART_CONTENT,

      method: "post",

      data: {
        currency,
        products: [
          {
            productType,
            code,
            quantity,
            amount,
            measure,
            smile: formula,
            mode: "Add",
            id,
            cdId,
          },
        ],
      },
    });

    if (res[2] === 400) {
      toastNoCartModify();
      return;
    }

    if (res[0]) {
      return res[0];
    }

    return false;
  } catch (e) {
    console.log("Something went wrong!", e);
  }
}

export function clearCart() {
  return customFetch({
    method: "post",
    url: endpoints.CART_CLEAR,
  });
}

export async function addBillingAddress(data) {
  return customFetch({
    method: "post",
    url: `${endpoints.CART_ADD_BILLING_ADDRESS}`,
    data,
  });
}

// @TODO Recheck logic from prev DEV and write code in a proper way with error handling
export async function putContent(props) {
  const { items, currency } = props;

  try {
    const res = await customFetch({
      url: endpoints.CART_CONTENT,
      method: "put",
      data: {
        currency,
        products: items,
      },
    });

    if (res[2] === 400) {
      toastNoCartModify();
      return;
    }

    if (res[0]) {
      return true;
    }

    return false;
  } catch (e) {
    console.log("Something went wrong!");
  }
}

export default {
  fetchCartInfo,
  postCartContent,
  fetchItems,
  allToCart,
  removeItem,
  updateCount,
  clearCart,
  addBillingAddress,
  putContent,
};
