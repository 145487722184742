import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import { blue } from "ui/colors";

export const ProgressBarContainer = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const ProgressWrapper = styled(Box)`
  width: 100%;
  margin-right: 8px;
`;

export const ProgressValue = styled(Typography)`
  color: ${blue};
  min-width: 35px;
`;

export const LoaderWrapper = styled.div`
  width: 500px;
  margin: 250px auto 227px;
`;

export const TitleTypography = styled(Typography)`
  margin-top: 32px;
  letter-spacing: 0.17px;
  line-height: 19px;
  font-size: 16px;
  text-align: center;
  font-weight: 600;
  color: ${blue};
`;
