import { endpoints } from "constants";
import { customFetchDelayedMiddleware } from "context/sseContext";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { encode } from "utils";
import { RequestMethods } from "utils/customFetch";
import { events, useAmplitudeState } from "../../../context/amplitude-context";
import {
  SubCategoryCardTitle,
  SubCategoryCardWrapper,
} from "./SubCategoryCard.styled";

type SubCategoryCardProps = {
  cardItem: {
    urlName: string;
    name: string;
  };
};

const SubCategoryCard = ({
  cardItem: { urlName, name },
}: SubCategoryCardProps) => {
  const [codes, setCodes] = useState<
    { code: string; catalog: string }[] | null
  >(null);
  const history = useHistory();
  const { path1, path2 } = useParams<{ path1: string; path2?: string }>();
  const { amplitude } = useAmplitudeState();

  const isFetching = codes === null;
  const fullPath = [path1, path2 !== path1 && path2, urlName]
    .filter(Boolean)
    .join("/");

  useEffect(() => {
    const fetchData = async () => {
      const response = await customFetchDelayedMiddleware({
        url: `${endpoints.CATALOG_CATEGORIES_BY_PATH}/${fullPath}`,
        method: RequestMethods.GET,
      });

      if (response[0]?.products?.length) {
        setCodes(response[0].products);
      } else {
        setCodes([]);
      }
    };

    fetchData();
  }, [fullPath]);

  const handleNavigate = () => {
    const finalPath = `/categories/${fullPath}`;
    const query = codes?.length
      ? `catalog?q=${encode(codes.map(({ code }) => code))}`
      : "";

    history.push(`${finalPath}/${query}`);

    amplitude.logEvent(events.category_click, {
      type: name,
    });
  };

  return (
    <SubCategoryCardWrapper isFetching={isFetching} onClick={handleNavigate}>
      <SubCategoryCardTitle>{name}</SubCategoryCardTitle>
    </SubCategoryCardWrapper>
  );
};

export default SubCategoryCard;
