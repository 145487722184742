import { Typography } from "@mui/material";
import styled from "styled-components";

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 24px;
`;

export const Content = styled.div`
  background-color: white;
  padding-top: 20px;
  padding-bottom: 60px;
`;

export const Title = styled(Typography)`
  font-size: 36px;
  letter-spacing: 0;
`;
