import { endpoints } from "constants";
import { customFetchDelayedMiddleware } from "context/sseContext";
import { Breadcrumb } from "shared/types/breadcrumb";
import { RequestMethods } from "utils/customFetch";

export const fetchAndUpdateCategoriesByPath = async (array: Breadcrumb[]) => {
  const paths = array.reduce((acc, item, index) => {
    const formattedTitle = item.title.toLowerCase().replace(/\s+/g, "-");
    if (index === 0) {
      acc.push(formattedTitle);
    } else {
      acc.push(`${acc[index - 1]}/${formattedTitle}`);
    }
    return acc;
  }, [] as string[]);

  const responses = await Promise.all(
    paths.map((path) =>
      customFetchDelayedMiddleware({
        url: `${endpoints.CATALOG_CATEGORIES_BY_PATH}/${path}`,
        method: RequestMethods.GET,
      })
    )
  );

  responses.forEach((response, index) => {
    if (response?.length > 0) {
      array[index].title = response[0].name;
    }
  });

  return array;
};
