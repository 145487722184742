import React from "react";
import classNames from "classnames";
import { Wrapper } from "./Table.styled";

type TableProps = {
  children: React.ReactNode;
  variant?: string;
};

const Table = ({ children, variant }: TableProps) => {
  const classnames = classNames("table", {
    [`table--variant--${variant}`]: variant,
  });

  return (
    <Wrapper className={classnames}>
      <table className="table__table">
        <tbody>{children}</tbody>
      </table>
    </Wrapper>
  );
};

type TableRowProps = {
  children: React.ReactNode;
  dataTestAttr?: string;
  className?: string;
};

const TableRow = ({ children, dataTestAttr, className }: TableRowProps) => {
  const classes = classNames("table__row", {
    [className]: className,
  });

  return (
    <>
      <tr className={classes} data-test={dataTestAttr}>
        {children}
      </tr>
      <tr className="table__spacing-row">
        <td className="table__spacing-td"></td>
      </tr>
    </>
  );
};

type TableCellProps = {
  children: React.ReactNode;
  title?: string;
  minWidth?: boolean;
  verticalCenter?: boolean;
  noXPaddings?: boolean;
};

const TableCell = ({
  children,
  title,
  minWidth,
  verticalCenter,
  noXPaddings,
}: TableCellProps) => {
  const classes = classNames("table__td", {
    "table__td--minWidth": minWidth,
    "table__td--verticalCenter": verticalCenter,
    "table__td--noXPaddings": noXPaddings,
  });

  if (title) {
    return (
      <td className={classes}>
        <div>
          <TableTdLabel>{title}</TableTdLabel>
        </div>
        <div>{children}</div>
      </td>
    );
  }

  return (
    <td className={classes}>
      <div>{children}</div>
    </td>
  );
};

type TableTdLabelProps = {
  children: React.ReactNode;
};

const TableTdLabel = ({ children }: TableTdLabelProps) => {
  return <div className="table__td-label">{children}</div>;
};

Table.Row = TableRow;
Table.Cell = TableCell;
Table.TdLabel = TableTdLabel;

export default Table;
