import React from "react";
import { ProductTypesV2 } from "constants/productTypes";
import { useProductCardProps } from "ui/ProductCard/ProductCard.container";
import { CatalogGridCard } from "ui/ProductCard/CatalogGridCard/CatalogGridCard";
import { CatalogListCard } from "ui/ProductCard/CatalogListCard/CatalogListCard";

const config = {
  [ProductTypesV2.BB]: {
    catalog: { title: "Product class", order: 1 },
    CAS: { title: "CAS number", order: 2 },
    molecularWeight: { title: "MW", order: 3 },
    formula: { title: "Formula", order: 4 },
    transportConditions: { title: "Transport conditions", order: 5 },
    storageConditions: { title: "Storage conditions", order: 6 },
  },
  [ProductTypesV2.SCR]: {
    catalog: { title: "Product class", order: 1 },
    collection: { title: "Screening Collection", order: 2 },
    molecularWeight: { title: "MW", order: 3 },
    formula: { title: "Formula", order: 4 },
    transportConditions: { title: "Transport conditions", order: 5 },
    storageConditions: { title: "Storage conditions", order: 6 },
  },
  [ProductTypesV2.EBC]: {
    catalog: { title: "Product class", order: 1 },
    name: { title: "Name", order: 2 },
    CAS: { title: "CAS number", order: 3 },
    formula: { title: "Formula", order: 4 },
    transportConditions: { title: "Transport conditions", order: 5 },
    storageConditions: { title: "Storage conditions", order: 6 },
  },
  [ProductTypesV2.MADE]: {
    catalog: { title: "Product class", order: 1 },
    name: { title: "Name", order: 2 },
    CAS: { title: "CAS number", order: 3 },
    formula: { title: "Formula", order: 4 },
    transportConditions: { title: "Transport conditions", order: 5 },
    storageConditions: { title: "Storage conditions", order: 6 },
  },
  [ProductTypesV2.REALDB]: {
    catalog: { title: "Product class", order: 1 },
    clogp: { title: "CLogP", order: 2 },
    molecularWeight: { title: "MW", order: 3 },
    tpsa: { title: "TPSA", order: 4 },
    transportConditions: { title: "Transport conditions", order: 5 },
    storageConditions: { title: "Storage conditions", order: 6 },
  },
};

const getConfigByCatalog = (catalog: ProductTypesV2) => {
  return {
    config: catalog ? config[catalog] : config[ProductTypesV2.BB],
    separators: [],
    limit: 6,
  };
};

export const CatalogCardContainer = ({
  item = {},
  images,
  image,
  listMode,
  isRelated,
  proposal,
}) => {
  const {
    buttonLabel,
    productImage,
    currency,
    currentPrice,
    setCurrentPrice,
    readyToCart,
    href,
    handleClick,
    handleAdd,
    handleAddListItemToCart,
    isAddingToCart,
  } = useProductCardProps({
    item,
    image,
    images,
    isRelated,
    proposal,
  });

  return listMode ? (
    <CatalogListCard
      catalogItem={item}
      image={productImage}
      code={(item?.product && item?.product?.code) || ""}
      handleClick={handleClick}
      href={href}
      isAddingToCart={isAddingToCart}
      handleAddListItemToCart={handleAddListItemToCart}
      currency={currency}
    />
  ) : (
    <CatalogGridCard
      item={item}
      infoListConfig={getConfigByCatalog(item?.product?.catalog)}
      image={productImage}
      code={(item?.product && item?.product?.code) || ""}
      href={href}
      handleClick={handleClick}
      handleAdd={handleAdd}
      readyToCart={readyToCart}
      buttonLabel={buttonLabel}
      isAddingToCart={isAddingToCart}
      currency={currency}
      currentPrice={currentPrice}
      setCurrentPrice={setCurrentPrice}
    />
  );
};
