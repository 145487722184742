import React from "react";
import {
  CategoriesCardDescription,
  CategoriesCardTitle,
  CategoriesCardWrapper,
} from "./CategoriesCard.styled";
import { useHistory } from "react-router-dom";
import {
  events,
  useAmplitudeState,
} from "../../../../../../context/amplitude-context";

type CategoriesCardProps = {
  name: string;
  urlName: string;
  description?: string;
};

const CategoriesCard = ({
  name,
  urlName,
  description,
}: CategoriesCardProps) => {
  const { amplitude } = useAmplitudeState();
  const history = useHistory();

  const handleNavigate = () => {
    history.push(`/categories/${urlName}`);
    amplitude.logEvent(events.category_click, {
      type: name,
    });
  };

  return (
    <CategoriesCardWrapper onClick={handleNavigate}>
      <CategoriesCardTitle>{name}</CategoriesCardTitle>
      {description && (
        <CategoriesCardDescription>{description}</CategoriesCardDescription>
      )}
    </CategoriesCardWrapper>
  );
};

export default CategoriesCard;
