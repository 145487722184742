import React from "react";
import { useFormik } from "formik";
import { FormControl, Radio, RadioGroup } from "@mui/material";
import {
  FilterWrapper,
  FormControlLabelStyled,
  FormControlsWrapper,
  FormControlWrapper,
  FrameFormWrapper,
  SearchButtonWrapper,
  SectionTitleTypographyStyled,
} from "./MarvinFrame.styles";
import { FilterTypes } from "../../pages/catalog/types";
import FilterFromTo from "../../pages/catalog/parts/FilterFromTo/FilterFromTo";
import InputUpdated from "../../ui/InputUpdated/InputUpdated";
import { FilterLabel } from "../../pages/catalog/parts/FilterFromTo/FilterFromTo.styles";
import { Button } from "../../ui";
import { searchAsTypes } from "../../constants";
import { SearchIcon } from "../../ui/icons";
import { catalogFiltersRegisterSchema } from "../../pages/catalog/components/CatalogFilters/filtersConfig";
import { SearchAsTypes } from "../../constants/searchAsTypes";
import { INFINITY_PLACEHOLDER } from "constants/infinityPlaceholder";

type MarvinFrameFormProps = {
  onSubmit: (values: any) => void;
};

type FormValues = {
  similarity?: string;
  sstype?: SearchAsTypes;
  [FilterTypes.MW]?: {
    from?: string;
    to?: string;
  };
  [FilterTypes.CLogP]?: {
    from: string;
    to: string;
  };
};

const MarvinFrameForm = ({ onSubmit }: MarvinFrameFormProps) => {
  const formik = useFormik({
    initialValues: {
      sstype: searchAsTypes.SUB,
    },
    validationSchema: catalogFiltersRegisterSchema,
    onSubmit: async (values: FormValues) => {
      onSubmit(values);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <FrameFormWrapper>
        <FormControlsWrapper>
          <FormControlWrapper>
            <SectionTitleTypographyStyled variant="subtitle1">
              Search Type
            </SectionTitleTypographyStyled>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={formik.values.sstype || searchAsTypes.SUB}
                onChange={formik.handleChange}
                name="sstype"
              >
                <FormControlLabelStyled
                  value={searchAsTypes.EXACT}
                  $active={formik.values.sstype === searchAsTypes.EXACT}
                  control={<Radio />}
                  label="Exact"
                />
                <FormControlLabelStyled
                  value={searchAsTypes.SUB}
                  $active={formik.values.sstype === searchAsTypes.SUB}
                  control={<Radio />}
                  label="Substructure"
                />
                <FormControlLabelStyled
                  value={searchAsTypes.SIM}
                  $active={formik.values.sstype === searchAsTypes.SIM}
                  control={<Radio />}
                  label="Similarity"
                />
              </RadioGroup>
            </FormControl>
          </FormControlWrapper>

          <FormControlWrapper>
            <SectionTitleTypographyStyled variant="subtitle1">
              Advanced Search
            </SectionTitleTypographyStyled>
            <FilterWrapper>
              <FilterFromTo
                label="MW"
                fieldName={FilterTypes.MW}
                formik={formik}
                inputHeight="60px"
                placeholderFrom="0.00"
                placeholderTo={INFINITY_PLACEHOLDER}
              />
            </FilterWrapper>
            <FilterWrapper>
              <FilterFromTo
                label="ClogP"
                fieldName={FilterTypes.CLogP}
                formik={formik}
                inputHeight="60px"
                placeholderFrom="-100.0000"
                placeholderTo="100.0000"
              />
            </FilterWrapper>
            {formik.values.sstype === searchAsTypes.SIM && (
              <FilterWrapper>
                <FilterLabel>Similarity coefficient</FilterLabel>
                <InputUpdated
                  type="number"
                  name="similarity"
                  value={formik.values.similarity}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.similarity &&
                    Boolean(formik.errors.similarity)
                  }
                  helperText={
                    formik.touched.similarity && formik.errors.similarity
                  }
                  placeholder={"0.01"}
                  inputProps={{
                    step: "0.01",
                  }}
                  InputProps={{
                    style: { height: "60px" },
                  }}
                />
              </FilterWrapper>
            )}
          </FormControlWrapper>
        </FormControlsWrapper>

        <SearchButtonWrapper>
          <Button
            iconLeft={<SearchIcon />}
            size="small"
            type="submit"
            style={{
              alignSelf: "center",
              padding: "16px 40px",
              height: "56px",
            }}
          >
            Structure Search
          </Button>
        </SearchButtonWrapper>
      </FrameFormWrapper>
    </form>
  );
};

export default MarvinFrameForm;
