import React from "react";
import { useSelector } from "react-redux";
import { utilSelectors } from "redux/util";

const AddressContent = ({ item }) => {
  const countries = useSelector(utilSelectors.selectCountries);
  const usStates = useSelector(utilSelectors.selectUsStates);

  return (
    <>
      <p>
        {countries?.[item?.country]}, {item?.city}
        {item?.state && <>, {usStates?.[item?.state]}</>}
        {item?.address}
        {item?.postalCode && <>, {item?.postalCode}</>}
        {item?.company && <>, {item?.company}</>}
        {item?.vat && <>, {item?.vat}</>}
      </p>
    </>
  );
};

export default AddressContent;
