import ArrowRightIconImage from "icons/arrow-right-2.svg";
import styled from "styled-components";
import { accentColor } from "ui/colors";

export const SubCategoryCardWrapper = styled.div<{ isFetching: boolean }>`
  box-sizing: border-box;
  position: relative;
  display: flex;
  height: 69px;
  padding: 24px;
  flex-basis: calc(33% - 12px);
  align-items: center;
  gap: 10px;
  align-self: stretch;
  cursor: pointer;
  background: #ffffff;
  border-radius: 8px;
  pointer-events: ${(props) => (props.isFetching ? "none" : "auto")};

  &:hover {
    color: ${accentColor};
    outline: 1px solid ${accentColor};
  }

  &:before {
    content: "";
    position: absolute;
    background-image: url(${ArrowRightIconImage});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100%;
    width: 24px;
    height: 24px;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
  }
`;

export const SubCategoryCardTitle = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
