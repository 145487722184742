import React from "react";
import { LikeLinkButton } from "../../../../components";
import { Wrapper, Group, Title, TextRow, Header } from "./CheckOrder.styles";
import { shipmentOptions } from "../carrier/Carrier.container";
import { StepNumber } from "../../CheckoutAccordion/CheckoutAccordion.styled";

const CheckOrder = ({
  data,
  handleEdit,
  countries,
  usStates,
  paymentTypeName,
}) => {
  return (
    <Wrapper>
      <Group>
        <Header>
          <StepNumber open={true}>1</StepNumber>
          <Title variant="h2">Contact Person for Shipping and Billing</Title>
          <LikeLinkButton onClick={() => handleEdit("step1")}>
            Edit
          </LikeLinkButton>
        </Header>
        <TextRow>
          {data?.contactPerson?.title !== "Undefined"
            ? data?.contactPerson?.title + ". "
            : null}{" "}
          {data?.contactPerson?.firstName} {data?.contactPerson?.lastName}
        </TextRow>
        <TextRow>{data?.contactPerson?.email}</TextRow>
        <TextRow>{data?.contactPerson?.phone}</TextRow>
        <TextRow>{data?.contactPerson?.company}</TextRow>
      </Group>

      <Group>
        <Header>
          <StepNumber open={true}>2</StepNumber>
          <Title variant="h2">Billing Address</Title>
          <LikeLinkButton onClick={() => handleEdit("step2")}>
            Edit
          </LikeLinkButton>
        </Header>
        <TextRow>{countries?.[data?.billing?.country]}</TextRow>
        {data?.billing?.state ? (
          <TextRow>{usStates?.[data?.billing?.state]}</TextRow>
        ) : (
          ""
        )}
        <TextRow>{data?.billing?.city}</TextRow>
        <TextRow>{data?.billing?.addressLine1}</TextRow>
        <TextRow>{data?.billing?.zip}</TextRow>
        <TextRow>{data?.billing?.phone}</TextRow>
        <TextRow>{data?.billing?.company}</TextRow>
        {data?.billing?.vat ? <TextRow>{data?.billing?.vat}</TextRow> : ""}
      </Group>

      <Group>
        <Header>
          <StepNumber open={true}>3</StepNumber>
          <Title variant="h2">Shipping Address</Title>
          <LikeLinkButton onClick={() => handleEdit("step3")}>
            Edit
          </LikeLinkButton>
        </Header>
        <TextRow>{countries?.[data?.shipping?.country]}</TextRow>
        {data?.shipping?.state ? (
          <TextRow>{usStates?.[data?.shipping?.state]}</TextRow>
        ) : (
          ""
        )}
        <TextRow>{data?.shipping?.city}</TextRow>
        <TextRow>{data?.shipping?.addressLine1}</TextRow>
        <TextRow>{data?.shipping?.zip}</TextRow>
        <TextRow>{data?.shipping?.phone}</TextRow>
        <TextRow>{data?.shipping?.company}</TextRow>
        {data?.shipping?.vat ? <TextRow>{data?.shipping?.vat}</TextRow> : ""}
      </Group>

      <Group>
        <Header>
          <StepNumber open={true}>4</StepNumber>
          <Title variant="h2">Shipping Options</Title>
          <LikeLinkButton onClick={() => handleEdit("step4")}>
            Edit
          </LikeLinkButton>
        </Header>
        <TextRow>{data?.carrier?.title}</TextRow>
        {!!data?.carrierId && <TextRow>Carrier ID: {data?.carrierId}</TextRow>}
        {!data?.carrierId && data?.carrierShipmentOption ? (
          <TextRow>
            {data.carrierShipmentOption === shipmentOptions.STANDARD
              ? "Standard Shipment"
              : "Partial Shipment"}
          </TextRow>
        ) : null}
      </Group>

      <Group>
        <Header>
          <StepNumber open={true}>5</StepNumber>
          <Title variant="h2">Payment Type</Title>
          <LikeLinkButton onClick={() => handleEdit("step5")}>
            Edit
          </LikeLinkButton>
        </Header>
        <TextRow>{paymentTypeName}</TextRow>
      </Group>
    </Wrapper>
  );
};

export default CheckOrder;
