import { useEffect, useState } from "react";
import { endpoints } from "constants/index";
import { customFetch } from "utils";

export const fetchOrderDetails = async (orderId: string) => {
  return customFetch({
    url: `${endpoints.CUSTOMERS_ORDERS}/by/id/${orderId}`,
  });
};

const useFetchOrderDetails = (orderId) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  // @TODO Write correct code to handle error
  const [error] = useState("");

  useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await fetchOrderDetails(orderId);

      if (res[0]) {
        setData(res[0]);
      }

      setLoading(false);
    })();
  }, [orderId]);

  return {
    loading,
    data,
    error,
  };
};

export default useFetchOrderDetails;
