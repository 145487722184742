import React from "react";
import { Breadcrumb } from "shared/types/breadcrumb";
import { Link } from "../index";
import { InactiveLink, List, ListItem } from "./Breadcrumbs.styled";
import { Container } from "@mui/material";

type BreadcrumbsProps = {
  breadcrumbItems: Breadcrumb[];
};

const Breadcrumbs = ({ breadcrumbItems }: BreadcrumbsProps) => {
  return (
    <Container>
      <List>
        {breadcrumbItems.map((breadcrumb, index) => {
          return (
            <ListItem key={index}>
              {breadcrumb.href ? (
                <Link to={breadcrumb.href}>{breadcrumb.title}</Link>
              ) : (
                <InactiveLink>{breadcrumb.title}</InactiveLink>
              )}
            </ListItem>
          );
        })}
      </List>
    </Container>
  );
};

export default Breadcrumbs;
