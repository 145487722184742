import { darkBlue, lineColor } from "../../../ui/colors";
import styled from "styled-components";

export const SubtotalWithHintWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const OrderDetailsItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 14px 0;
  border-bottom: 1px solid ${lineColor};
  color: ${darkBlue};

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 18px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

export const OrderDetailsWrapper = styled.div`
  ${OrderDetailsItem} {
    &:last-child {
      border-bottom: none;
    }
  }
`;
