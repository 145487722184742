import React, { useEffect } from "react";
import {
  Page,
  Title,
  Wrapper,
  Content,
  Sidebar,
  CheckOrderWrapper,
  Paper,
  Header,
  AccordionWrapper,
} from "./Checkout.styles";
import { Container } from "../../ui";
import CheckOrder from "./parts/check-order/CheckOrder.container";
import Additional from "./parts/additional/Additional.container";
import Thank from "./parts/thank/Thank";
import Error from "./parts/error/Error";
import SendingOrder from "./parts/sending-order/SendingOrder";
import RestrictionModal from "./parts/restriction-modal/RestrictionModal.container";
import PostalCodeIssueModal from "./parts/postal-code-issue-modal/PostalCodeIssueModal.container";
import animateScrollTo from "animated-scroll-to";
import CheckoutAccordionContainer from "./CheckoutAccordion/CheckoutAccordion.container";
import CheckoutSidebarContainer from "./CheckoutSidebar/CheckoutSidebar.container";

const Checkout = ({
  modeRegulator,
  items,
  handleEdit,
  handleConfirm,
  readyToConfirm,
  cartItems,
  orderSummary,
  restriction,
  confirmTitle,
  postalCodeIssue,
  whenCanBePaid,
  isDisabledConfirmButton,
  pendingTimerCallback,
  handleDiscard,
  showDiscard,
}) => {
  useEffect(() => {
    if (
      [modeRegulator.modes.THANK, modeRegulator.modes.ERROR].includes(
        modeRegulator.mode
      )
    ) {
      animateScrollTo(0);
    }
  }, [modeRegulator.mode]);

  return (
    <Page>
      <Container>
        <Header>
          <Title>Checkout</Title>
        </Header>

        {modeRegulator.mode === modeRegulator.modes.THANK && <Thank />}

        {modeRegulator.mode === modeRegulator.modes.ERROR && (
          <Error modeRegulator={modeRegulator} />
        )}

        {modeRegulator.mode === modeRegulator.modes.SENDING_ORDER && (
          <SendingOrder />
        )}

        {modeRegulator.mode !== modeRegulator.modes.SENDING_ORDER &&
          modeRegulator.mode !== modeRegulator.modes.THANK &&
          modeRegulator.mode !== modeRegulator.modes.ERROR && (
            <>
              <Wrapper>
                {modeRegulator.mode === modeRegulator.modes.CHECKOUT && (
                  <Content>
                    {items?.length !== 0 && (
                      <Paper>
                        <AccordionWrapper>
                          <CheckoutAccordionContainer
                            items={items}
                            modeRegulator={modeRegulator}
                            restriction={restriction}
                            postalCodeIssue={postalCodeIssue}
                          />
                        </AccordionWrapper>
                      </Paper>
                    )}
                    <RestrictionModal modal={restriction} />
                    <PostalCodeIssueModal modal={postalCodeIssue} />
                  </Content>
                )}

                {modeRegulator.mode === modeRegulator.modes.CHECK_ORDER && (
                  <Content>
                    <Paper>
                      <CheckOrderWrapper>
                        <CheckOrder handleEdit={handleEdit} />
                      </CheckOrderWrapper>
                    </Paper>
                    <Paper>
                      <Additional />
                    </Paper>
                  </Content>
                )}
                <Sidebar>
                  <CheckoutSidebarContainer
                    modeRegulator={modeRegulator}
                    handleConfirm={handleConfirm}
                    readyToConfirm={readyToConfirm}
                    cartItems={cartItems}
                    orderSummary={orderSummary}
                    confirmTitle={confirmTitle}
                    whenCanBePaid={whenCanBePaid}
                    isDisabledConfirmButton={isDisabledConfirmButton}
                    pendingTimerCallback={pendingTimerCallback}
                    handleDiscard={handleDiscard}
                    showDiscard={showDiscard}
                  />
                </Sidebar>
              </Wrapper>
            </>
          )}
      </Container>
    </Page>
  );
};

export default Checkout;
