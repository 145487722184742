import { addZeroes } from "utils";
import {
  OrderItemCode,
  OrderItemDetails,
  OrderItemPrice,
  OrderItemWrapper,
} from "../Order.styled";
import React from "react";
import { OrderItemWithCurrencyAndImage } from "../Order.types";
import { ImageContainer, OrderItem } from "./OrderItems.styled";
import { CustomizedTooltip } from "ui";
import { InfoIcon } from "ui/icons";

type OrderItemsProps = {
  orderItems: OrderItemWithCurrencyAndImage[];
};

const OrderItems = ({ orderItems }: OrderItemsProps) => {
  return (
    <>
      {orderItems?.length &&
        orderItems.map((orderItem, index) => {
          return (
            <OrderItem key={index}>
              <div className="g-flex g-flex-vertical-center">
                <ImageContainer>
                  <img
                    alt={`Product - ${orderItem?.code}`}
                    src={orderItem?.image}
                  />
                </ImageContainer>

                <OrderItemWrapper>
                  <OrderItemCode>
                    {orderItem?.code}{" "}
                    <span>
                      x {orderItem?.quantity} x {orderItem?.amount}{" "}
                      {orderItem?.measure}
                    </span>
                    {Boolean(orderItem?.isInquery) && (
                      <CustomizedTooltip
                        title="Feasible compound. Price will be confirmed by our sales representative"
                        placement="top"
                      >
                        <InfoIcon />
                      </CustomizedTooltip>
                    )}
                  </OrderItemCode>
                  <OrderItemDetails>
                    <span>Purity: {orderItem?.purity}%</span>
                    {orderItem?.isInquery && (
                      <span>
                        {", "}
                        {orderItem?.isInquery ? "Synthesis" : "In Stock"}
                      </span>
                    )}
                  </OrderItemDetails>
                </OrderItemWrapper>
              </div>

              <OrderItemPrice>
                {addZeroes(orderItem?.price)} {orderItem?.currency}
              </OrderItemPrice>
            </OrderItem>
          );
        })}
    </>
  );
};

export default OrderItems;
