import { useEffect, useState } from "react";
import useFetchOrderItems from "./useFetchOrderItems";
import useFetchOrderDetails from "./useFetchOrderDetails";

type UseFetchOrderWithDetailsProps = {
  orderId: string;
};

const useFetchOrderWithDetails = ({
  orderId,
}: UseFetchOrderWithDetailsProps) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  // @TODO Check logic to handle errors
  const [error] = useState("");

  const details = useFetchOrderDetails(orderId);
  const items = useFetchOrderItems(orderId);

  useEffect(() => {
    if (details.data && items.data) {
      const currency = details.data?.currency;

      const newData = {
        details: details.data,
        items: items.data.map((el) => {
          return {
            ...el,
            currency,
          };
        }),
      };

      setData(newData);
      setLoading(false);
    }
  }, [details.data, items.data]);

  useEffect(() => {
    if (details.loading || items.loading) {
      setLoading(true);
    }
  }, [details.loading, items.loading]);

  return {
    loading,
    data,
    error,
  };
};

export default useFetchOrderWithDetails;
