import React from "react";
import OrderPricesDetails from "../../../../../shared/ui/OrderPricesDetails/OrderPricesDetails";

type OrderPricesProps = {
  details: any;
  isAnyItemInquery: boolean;
};

const OrderPrices = ({ details, isAnyItemInquery }: OrderPricesProps) => {
  const { price, currency, type } = details;

  return (
    <OrderPricesDetails
      price={price}
      currency={currency}
      isAnyItemInquery={isAnyItemInquery}
      type={type}
    />
  );
};

export default OrderPrices;
