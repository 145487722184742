import React from "react";
import CheckoutCartItem from "./CheckoutCartItem";
import { getPriceText } from "shared/utils/Cart.utils";

const CheckoutCartItemContainer = ({ cartItem }) => {
  const { quantity: count, code: title, image, isInquery } = cartItem;
  const amount = `${cartItem.amount} ${cartItem.measure}`;
  const price = `${getPriceText(cartItem.price, cartItem.currency)}`;

  return (
    <CheckoutCartItem
      count={count}
      amount={amount}
      price={price}
      title={title}
      image={image?.url}
      isInquery={!!isInquery}
    />
  );
};

export default CheckoutCartItemContainer;
