import styled from "styled-components";

export const Wrapper = styled.div`
  z-index: 1300;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(23, 43, 78, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
`;
