import { rootPages } from "constants";
import { Profile } from "./ProfileSidebar.types";

export const getUsernameAndAvatarLetters = (profile: Profile) => {
  const username =
    profile?.firstName && profile?.lastName
      ? `${profile?.firstName} ${profile?.lastName}`
      : null;
  const firstNameLetter = profile?.firstName?.charAt(0);
  const lastNameLetter = profile?.lastName?.charAt(0);
  const avatarLetters =
    firstNameLetter && lastNameLetter ? firstNameLetter + lastNameLetter : null;

  return {
    username,
    avatarLetters,
  };
};

export const getLinkHref = (alias: string) =>
  alias === rootPages.PROFILE
    ? `/${rootPages.PROFILE}`
    : `/${rootPages.PROFILE}/${alias}`;
