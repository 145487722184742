import React from "react";
import {
  Wrapper,
  Image,
  Row,
  Info,
  InfoWrapper,
  Title,
} from "./CheckoutCartItem.styled";

type CheckoutCartItemProps = {
  title: string;
  image: string;
  count: string;
  amount: string;
  price: string;
};

const CheckoutCartItem = ({
  title,
  image,
  count,
  amount,
  price,
}: CheckoutCartItemProps) => {
  return (
    <Wrapper>
      <Image style={{ backgroundImage: `url(${image})` }} />

      <InfoWrapper>
        <Title variant="h3">{title}</Title>
        <Info>
          <Row>
            {count} x {amount} x {price}
          </Row>
        </Info>
      </InfoWrapper>
    </Wrapper>
  );
};

export default CheckoutCartItem;
