import styled from "styled-components";

export const TablesWrapper = styled.div`
  & > *:first-child h2 {
    margin-bottom: 28px;
  }

  & > *:nth-child(3) h2 {
    margin-top: 28px;
    margin-bottom: 28px;
  }
`;
