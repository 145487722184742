import React from "react";
import { LikeLinkButton } from "../../../components";
import {
  Step,
  StepNumber,
  StepTitle,
  Header,
  Content,
  Wrapper,
  StepInfoContainer,
} from "./CheckoutAccordion.styled";

const CheckoutAccordion = ({ items, handleEdit, modeRegulator, ...rest }) => (
  <Wrapper>
    {items.map((item, idx) => {
      return (
        <Step
          key={idx}
          last={idx + 1 === items?.length}
          disabled={item.disabled}
          open={item.open}
        >
          <Header>
            <StepInfoContainer>
              <StepNumber
                open={item.open || !item.disabled}
                disabled={item.disabled}
              >
                {idx + 1}
              </StepNumber>
              <StepTitle variant="h2">{item.title}</StepTitle>
            </StepInfoContainer>
            {item.open === false && item.disabled !== true && (
              <LikeLinkButton
                style={{ fontSize: 20 }}
                onClick={() => {
                  handleEdit(`step${idx + 1}`);
                }}
              >
                Edit
              </LikeLinkButton>
            )}
          </Header>

          <Content open={item.open}>
            {item.component &&
              item.component({ modeRegulator, index: idx, ...rest })}
          </Content>
        </Step>
      );
    })}
  </Wrapper>
);

export default CheckoutAccordion;
