import { endpoints } from "constants";
import React, { useEffect, useState } from "react";
import { RequestMethods } from "utils/customFetch";
import { CategoriesWrapper } from "./Categories.styled";
import CategoriesCard from "./ui/CategoryCard/CategoryCard";
import { customFetchDelayedMiddleware } from "context/sseContext";

type Category = {
  name: string;
  urlName: string;
  description?: string;
};

const Categories = () => {
  const [categories, setCategories] = useState<Category[] | null>(null);

  useEffect(() => {
    const fetchCategories = async () => {
      const response = await customFetchDelayedMiddleware({
        url: `${endpoints.CATALOG_CATEGORIES_HOME}`,
        method: RequestMethods.GET,
      });

      if (response[0]) {
        setCategories(response[0]);
      } else {
        setCategories([]);
      }
    };

    fetchCategories();
  }, []);

  return (
    Boolean(categories?.length) && (
      <CategoriesWrapper>
        {categories.map((category: Category) => (
          <CategoriesCard
            key={category.name}
            name={category.name}
            urlName={category.urlName}
            description={category?.description}
          />
        ))}
      </CategoriesWrapper>
    )
  );
};

export default Categories;
