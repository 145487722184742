import React, { ReactNode, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { SearchInput } from "./SearchInput";
import {
  HintWrapper,
  InputWrapper,
  SearchHint,
} from "./SearchInputWithHint.styles";

type SearchInputWithHintProps = {
  children: ReactNode;
  control: any;
  onEnter: () => void;
  isLoading: boolean;
};

export const SearchInputWithHint = ({
  children,
  control,
  onEnter,
  isLoading,
}: SearchInputWithHintProps) => {
  const [isHintShown, showHint] = useState(false);

  return (
    <>
      <InputWrapper>
        <SearchInput
          isLoading={isLoading}
          control={control}
          onEnter={onEnter}
          onFocus={() => showHint(true)}
          onBlur={() => showHint(false)}
        />
        {children}
      </InputWrapper>
      <HintWrapper>
        <TransitionGroup>
          {isHintShown && (
            <CSSTransition
              key="search_hint"
              timeout={200}
              classNames="search-hint"
              unmountOnExit
              mountOnEnter
            >
              <SearchHint>
                Your search will automatically include the results from our
                Stock, REAL Database and MADE Building Blocks catalogs.
              </SearchHint>
            </CSSTransition>
          )}
        </TransitionGroup>
      </HintWrapper>
    </>
  );
};
