import React from "react";
import { LikeLinkButton } from "../../../components";
import { Loader } from "../../../ui";
import PendingTimerContainer from "../parts/pending-timer/PendingTimer.container";
import DiscardOrderContainer from "../parts/discard-order/DiscardOrder.container";
import CheckoutCartItemContainer from "../CheckoutCartItem/CheckoutCartItem.container";
import { CartItemResponse } from "../../../typings/DTO/data-contracts";
import { OrderSummary } from "../Checkout.types";
import {
  Button,
  Content,
  Footer,
  Header,
  List,
  ListItem,
  Scrollable,
  Title,
  Wrapper,
} from "./CheckoutSidebar.styled";
import { CustomizedTooltip } from "ui";
import { InfoCell } from "../../../features/super-cart/Summary/Summary.styled";
import { InfoIcon } from "../../../ui/icons";
import OrderPricesDetails from "../../../shared/ui/OrderPricesDetails/OrderPricesDetails";

type CheckoutSidebarProps = {
  cartItems: CartItemResponse[];
  handleEdit: VoidFunction;
  isLoading: boolean;
  handleConfirm: (items: CartItemResponse[]) => void;
  readyToConfirm: boolean;
  orderSummary: OrderSummary;
  confirmTitle: string;
  whenCanBePaid: string;
  isDisabledConfirmButton: boolean;
  pendingTimerCallback: () => void;
  handleDiscard: () => Promise<void>;
  showDiscard: boolean;
  inqueryItems: CartItemResponse[];
  inStockItems: CartItemResponse[];
};

const CheckoutSidebar = ({
  inqueryItems,
  inStockItems,
  handleEdit,
  isLoading,
  handleConfirm,
  readyToConfirm,
  orderSummary,
  confirmTitle,
  whenCanBePaid,
  isDisabledConfirmButton,
  pendingTimerCallback,
  handleDiscard,
  showDiscard,
}: CheckoutSidebarProps) => {
  if (isLoading) {
    return (
      <Wrapper>
        <Loader fullWidth paddings>
          Loading total...
        </Loader>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {Boolean(inStockItems.length) && (
        <>
          <Header>
            <Title variant="h2">In Stock</Title>
            <LikeLinkButton onClick={handleEdit}>Edit cart</LikeLinkButton>
          </Header>
          <Content>
            <Scrollable>
              <List>
                {inStockItems.map((cartItem, idx) => {
                  return (
                    <ListItem key={idx}>
                      <CheckoutCartItemContainer cartItem={cartItem} />
                    </ListItem>
                  );
                })}
              </List>
            </Scrollable>
          </Content>
        </>
      )}
      {Boolean(inqueryItems.length) && (
        <>
          <Header>
            <InfoCell>
              <Title variant="h2">Synthesis</Title>
              <CustomizedTooltip
                title="Our chemists will evaluate the synthesis and get 
back to you with price and lead time confirmation 
within 1-2 days. No worries, it’s free of charge."
                placement="right"
              >
                <InfoIcon />
              </CustomizedTooltip>
            </InfoCell>
          </Header>
          <Content>
            <Scrollable>
              <List>
                {inqueryItems.map((cartItem, idx) => {
                  return (
                    <ListItem key={idx}>
                      <CheckoutCartItemContainer cartItem={cartItem} />
                    </ListItem>
                  );
                })}
              </List>
            </Scrollable>
          </Content>
        </>
      )}
      <Footer>
        <OrderPricesDetails
          price={orderSummary}
          isAnyItemInquery={Boolean(inqueryItems.length)}
        />

        <PendingTimerContainer
          whenCanBePaid={whenCanBePaid}
          callback={pendingTimerCallback}
        />

        <Button
          onClick={handleConfirm}
          disabled={isDisabledConfirmButton}
          type="success"
          variant="success"
          style={{
            display: readyToConfirm ? "inline-flex" : "none",
          }}
        >
          {confirmTitle}
        </Button>

        {showDiscard && (
          <div
            style={{
              position: "relative",
              top: "10px",
              paddingBottom: "5px",
            }}
          >
            <DiscardOrderContainer handleDiscard={handleDiscard} />
          </div>
        )}
      </Footer>
    </Wrapper>
  );
};

export default CheckoutSidebar;
