import styled from "@emotion/styled";

export const CategoriesWrapper = styled.div`
  max-width: 952px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  gap: 16px;
  padding-bottom: 60px;
`;
