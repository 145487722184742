import { useDispatch, useSelector } from "react-redux";
import superCartActions from "../redux/actions";
import { userSelectors } from "redux/user";
import { currencySelectors } from "redux/currency";
import cartAPI from "../redux/api";
import { toastNoCartModify } from "utils";

const useCartHandlers = () => {
  const dispatch = useDispatch();
  const isAuth = useSelector(userSelectors.selectIsAuth);
  const currency = useSelector(currencySelectors.selectCurrent);

  const handleDelete = async ({ key, data }) => {
    if (isAuth) {
      dispatch(superCartActions.setCartInfo({ loading: true }));
      const response = await cartAPI.removeItem({
        id: data.id,
        cdId: 0,
        code: data.code,
      });
      if (response[0]) {
        dispatch(superCartActions.deleteItem(key));
        dispatch(superCartActions.setCartInfo({ loading: false }));
      } else {
        toastNoCartModify();
      }
    } else {
      dispatch(superCartActions.deleteItem(key));
    }
  };

  const handleChangeCount = async ({ key, data }) => {
    dispatch(
      superCartActions.updateItem({
        key: key,
        data: {
          quantity: data.quantity,
        },
      })
    );

    if (isAuth) {
      dispatch(superCartActions.setCartInfo({ loading: true }));

      const res = await cartAPI.updateCount({
        currency: currency.abbr,
        id: data.id,
        cdId: data.cdId,
        code: data.code,
        amount: data.amount,
        measure: data.measure,
        quantity: data.quantity,
        productType: data.productType,
        formula: ";)",
      });

      dispatch(superCartActions.requestCartInfo());
    }
  };

  const handleChangePriceSelect = async ({ key, data }) => {
    dispatch(superCartActions.updateItem({ key, data }));
    dispatch(superCartActions.setCartInfo({ loading: true }));

    if (isAuth) {
      const res = await cartAPI.updateCount({
        currency: currency.abbr,
        code: data.code,
        amount: data.amount,
        quantity: data.quantity,
        measure: data.measure,
        productType: data.productType,
        formula: ";)",
        id: data.id,
      });

      dispatch(superCartActions.requestCartInfo());
    }
  };

  const handleClearCart = async () => {
    if (isAuth) {
      const res = await cartAPI.clearCart();

      dispatch(superCartActions.clear());
    } else {
      dispatch(superCartActions.clear());
    }
  };

  return {
    handleDelete,
    handleChangeCount,
    handleChangePriceSelect,
    handleClearCart,
  };
};

export default useCartHandlers;
