import styled from "styled-components";
import { borderColor, lightBlue, lineColor } from "../colors";

export const Wrapper = styled.div`
  &.table {
    width: 100%;
    overflow-x: auto;
  }

  &.table--variant--headed-light-blue {
    border: 1px solid ${lightBlue};
    border-radius: 8px;

    .table__row:first-child {
      .table__td {
        background-color: ${lightBlue};
        font-weight: 500;
        font-size: 18px;
      }
    }

    .table__spacing-row {
      display: none;
    }
  }

  &.table--variant--paper {
    .table__td {
      &::after {
        background-color: #fff;
      }
    }
  }

  &.table--variant--bordered {
    .table__td {
      &::after {
        border-bottom: 1px solid ${borderColor};
        border-top: 1px solid ${borderColor};
      }

      &:last-child {
        &::after {
          border-right: 1px solid ${borderColor};
        }
      }

      &:first-child {
        &::after {
          border-left: 1px solid ${borderColor};
        }
      }
    }
  }

  &.table--variant--border-bottom {
    .table__row:not(:nth-last-child(2)) {
      .table__td {
        border-bottom: 1px solid ${lineColor};
      }
    }
  }

  .table__table {
    width: 100%;
    border-collapse: collapse;
  }

  .table__td {
    position: relative;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 30px;
    padding-right: 30px;
    vertical-align: top;

    &--minWidth {
      width: 1%;
    }

    &--verticalCenter {
      vertical-align: middle;
    }

    &--noXPaddings {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }

    &:first-of-type {
      padding-left: 28px;
      width: 28%;

      &::after {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
    }

    &:last-of-type {
      padding-right: 28px;

      &::after {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }

    & > div {
      position: relative;
      z-index: 1;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
    }
  }

  .table__spacing-td {
    padding-bottom: 12px;
  }

  .table__spacing-row {
    &:last-of-type {
      display: none;
    }
  }

  .table__td-label {
    font-size: 13px;
    margin-bottom: 8px;
    white-space: nowrap;
  }
`;
