import React from "react";
import { Table, Title } from "ui";
import { DeliveryData } from "./RushDeliveryModal.types";

type RushDeliveryModalTableProps = {
  list: DeliveryData[];
  title: string;
};

const RushDeliveryModalTable = ({
  list,
  title,
}: RushDeliveryModalTableProps) => {
  return (
    <>
      <Title variant="h2">{title}</Title>
      <Table variant="headed-light-blue">
        <Table.Row>
          <Table.Cell>Catalog ID</Table.Cell>
          <Table.Cell>Packing</Table.Cell>
          <Table.Cell>Amount</Table.Cell>
          <Table.Cell>Location</Table.Cell>
          <Table.Cell>Delivery Time</Table.Cell>
        </Table.Row>
        {list.map((cartItem, index) => {
          return (
            <Table.Row key={index}>
              <Table.Cell>{cartItem.catalogId}</Table.Cell>
              <Table.Cell>{cartItem.packing}</Table.Cell>
              <Table.Cell>{cartItem.amount}</Table.Cell>
              <Table.Cell>{cartItem.location}</Table.Cell>
              <Table.Cell>{cartItem.deliveryTime}</Table.Cell>
            </Table.Row>
          );
        })}
      </Table>
    </>
  );
};
export default RushDeliveryModalTable;
